import {
	Autocomplete,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { checkIsValidDate } from "../../utils";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import i18n from "../../l10n/strings.json";
import Guitar from "../../images/guitar.svg";
import Drums from "../../images/drums.svg";
import Violin from "../../images/Violin.svg";
import Trumpet from "../../images/trumpet.svg";
import { useEventsFilter } from "./useEventsFilter";
import useLocationAutocomplete from "../../hooks/useLocationAutocomplete/useLocationAutocomplete";
import { Constants } from "../../services/constants";
import GoogleLocationService from "../GoogleLocationService";
import ClearIcon from '@mui/icons-material/Clear';

type Genres = {
	// id: string;
	title: string;
};
type Instrument = {
	id: string;
	label: string;
	icon: string;
};

// const genreFilter:Array<Genres> = [
//     {
//         id: "jazz",
//         label: "Jazz"
//     },
//     {
//         id: "pop",
//         label: "Pop"
//     },
//     {
//         id: "classic",
//         label: "Classic"
//     },
//     {
//         id: "punk",
//         label: "Punk"
//     },
//     {
//         id: "metal",
//         label: "Metal"
//     },
// ];

const genreFilter: Array<Genres> = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber-Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];

const instrumentFilter: Array<Instrument> = [
	{
		id: "guitar",
		label: "Guitar",
		icon: Guitar,
	},
	{
		id: "drums",
		label: "Drums",
		icon: Drums,
	},
	{
		id: "violin",
		label: "Violin",
		icon: Violin,
	},
	{
		id: "trumpet",
		label: "Trumpet",
		icon: Trumpet,
	},
];

const marks = [
	{
		value: 0,
		label: 25,
	},
	{
		value: 25,
		label: 50,
	},
	{
		value: 50,
		label: 100,
	},
	{
		value: 75,
		label: 500,
	},
	{
		value: 100,
		label: "Any",
	},
];

function valuetext(value: number) {
	return `${value}`;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},

		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				margin: "3px",
				color: "white",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

const EventsFilter = (props: any) => {
	const [inputValue, setInputValue] = useState("");
	const [searchString, setSearchString] = useState("");
	const optionsLocationAutoComplete = useLocationAutocomplete(searchString);
	const theme = useTheme();
	const classes = useStyles();
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [eventFilterData, setEventFilterData] = useState<any>({
		city: "",
		distanceFromCity: "",
		date: "",
	});

	const [city, setCity] = useState<any>();
	const [distanceFromCity, setDistanceFromCity] = useState<any>();
	const [date, setDate] = useState<any>();
	const [duration, setDuration] =  useState<number | null>(null);
	const durationList = Constants.gigDuration.filter(item => item.label !== "Select Duration");;

	const {
		eventsFilter,
		setEventsFilter,
		isFilterActive,
		resetEventsFilter,
		displayDistance,
		setDisplayDistance,
		setViewMoreEvents,
		setEventActiveMenu,
	} = useEventsFilter();
	const [startDate, setStartDate] = useState<Date | null>(null);

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const updateSearchFilter = (field: string, value: any) => {
		switch (field) {
			case "eventTitle":
				setEventsFilter({
					...eventsFilter,
					title: value,
				});
				break;

			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});

				selectedGenres.sort();

				setEventsFilter({
					...eventsFilter,
					genres: selectedGenres,
				});
				break;

			case "connectionDate":
				setEventsFilter({
					...eventsFilter,
					startDateTime: value,
				});
				break;

			case "location":
				setEventsFilter({
					...eventsFilter,
					cityCountry: value,
				});
				getLocationLatLng(value).then((result) => {
					setEventsFilter((prevState: any) => {
						return {
							...prevState,
							latitude: result.lat,
					 		longitude: result.lng,
						}
					})
				});
				break;

			case "distanceFromCity":
				setEventsFilter({
					...eventsFilter,
					distanceFromCity: value,
				});
				break;

			default:
				break;
		}
	};

	const clearFilter = () => {
		setCity("");
		setStartDate(null);
		setDuration(0);
		resetEventsFilter();
		setViewMoreEvents(false);
		setEventActiveMenu("allEvents");
	};

	useEffect(() => {
		setStartDate(null);
	}, []);

	useEffect(() => {
		props.getEventFilters({
			cityCountry: city,
			startDateTime: date,
			distanceFromCity: distanceFromCity,
		});
	}, [city, date, distanceFromCity]);

	const handleSearchStringChange = (event: any) => {
		setSearchString(event.target.value);
	};

	return (
		<>
			<Grid
				className={`${classes.shadowedBox} fliter`}
				container
				xs={12}
				sx={{ height: "fit-content", pr: "20px", pl: "20px", pt: "12px" }}
			>
				<Grid item xs={12}>
					<Typography variant="h3" sx={{ textAlign: "center" }}>
						{i18n.events.filters.title}
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormHelperText className="filterLabel">Event Title</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={eventsFilter.title}
						onChange={(event: any) =>
							updateSearchFilter("eventTitle", event.target.value)
						}
						error={eventsFilter.title.length > 64}
						size="small"
					></TextField>
					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				</Grid>

				<Grid item xs={12}>
					<FormHelperText className="filterLabel">
						{i18n.usersmanagement.filters.genresTitle}
					</FormHelperText>

					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags"
						options={genreFilter}
						classes={classes}
						getOptionLabel={(option) => option.title}
						// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
						// defaultValue={}
						value={genreFilter.filter((genre: any) => {
							return eventsFilter.genres.includes(genre.title as never);
						})}
						renderInput={(params) => <TextField {...params} />}
						disableClearable={false}
						onChange={(event, newGenres) =>
							updateSearchFilter("genres", newGenres)
						}
					/>
					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				</Grid>

				{/* Date Field */}
				<Grid item xs={12}>
					<FormHelperText sx={{ ml: 0 }} className="filterLabel">
						<Typography variant="body4" color="customColor.label">
							Event duration
						</Typography>
					</FormHelperText>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							openTo="day"
							views={["year", "month", "day"]}
							inputFormat="MM/dd/yyyy"
							value={startDate}
							onChange={(newValue: Date | null) => {
								if (newValue) {
									// Convert the selected date to UTC
									const utcDate = new Date(
										Date.UTC(
											newValue.getFullYear(),
											newValue.getMonth(),
											newValue.getDate()
										)
									);

									setStartDate(utcDate);
									updateSearchFilter("connectionDate", utcDate);
								}
							}}
							// onChange={(newValue: Date | null) => {
							// 	if (checkIsValidDate(newValue)) {
							// 		setStartDate(newValue);
							// 		updateSearchFilter("connectionDate", newValue);
							// 	}
							// }}
							renderInput={(params) => (
								<TextField
									{...params}
									onKeyDown={(e) => e.preventDefault()}
									sx={{
										"&.MuiTextField-root": {
											backgroundColor: "customColor.gray1",
											width: "100%",
										},
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
										},
									}}
								/>
							)}
						/>
					</LocalizationProvider> */}

					<Select
						labelId="demo-simple-select-label"
						value={
							eventsFilter.startDateTime ? eventsFilter.startDateTime : duration ?? ""
						}
						onChange={(event: any) => {
							setDuration(event.target.value as number);
							updateSearchFilter("connectionDate", event.target.value);
						}}
						endAdornment={
							duration !== null ? (
								<InputAdornment position="end">
									<IconButton
										aria-label="clear"
										onClick={() => {
											setDuration(null);
											updateSearchFilter("connectionDate", null);
										}}
										size="small"
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							) : null
						}
						sx={{
							backgroundColor: "customColor.gray1",
							color:
								duration === 0 && !eventsFilter.startDateTime
									? "gray"
									: "customColor.black",
							width: "100%",
						}}
					>
						{durationList.map((duration) => (
							<MenuItem key={duration.value} value={duration.value}>
								{duration.label}
							</MenuItem>
						))}
					</Select>
				</Grid>

				{/* City Field */}
				<Grid item xs={12}>
					<FormHelperText className="filterLabel">
						{i18n.usersmanagement.filters.cityTitle}
					</FormHelperText>
					<GoogleLocationService
						defaultValue={city}
						onLocationChange={(locationDesc: string) => {
							setCity(locationDesc);
							updateSearchFilter("location", locationDesc);
						}}
					/>

					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				</Grid>

				{/* Distance from City Field */}
				<Grid item xs={12} mr={1.2}>
					<FormHelperText className="filterLabel">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>{i18n.events.filters.distanceTitle}</div>
							<div>Miles</div>
						</div>
					</FormHelperText>
					{/* <Slider
                        defaultValue={50}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        onChange={(event: any, distance: any) => {
                            // setDistanceFromCity(distance)
                            updateSearchFilter("distanceFromCity", distance);
                        }}
                    /> */}

					<Slider
						defaultValue={25}
						disabled={
							eventsFilter.cityCountry == null ||
							eventsFilter.cityCountry.length === 0
						}
						value={displayDistance}
						step={null}
						marks={marks}
						onChange={(event, value) => {
							// Handle the value change
							let updatedValue: any;
							marks.map((mark) => {
								if (mark.value === value) {
									if (mark.value === 100) {
										updatedValue = 100000;
										setDisplayDistance(100);
									} else {
										updatedValue = mark.label;
										setDisplayDistance(mark.value);
									}
									updateSearchFilter("distanceFromCity", +updatedValue);
								}
							});
							// setTest(value);
						}}
						valueLabelFormat={valuetext}
					/>
				</Grid>

				{isFilterActive && (
					<Grid container xs={12} justifyContent="center" marginY={2}>
						<Button
							variant="text"
							sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
							size="small"
							onClick={() => {
								props.fetchData();
								props.handleCardView();
								clearFilter();
							}}
						>
							<Typography variant="h6">Clear Filter</Typography>
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default EventsFilter;
