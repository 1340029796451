import React, { useEffect, useState } from "react";
import ModalInputField from "./ModalInputField";
import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import { ModalBody, Box } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";

import { ActionName } from "../../../types";
import ConvoModal from "./ConvoModal";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { UserInterface, musicianCardInterface } from "../../../types/types";
import { useUser } from "../../../context/UserContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";

interface ConversationTitleModalProps {
	title: string;
	isModalOpen: boolean;
	onCancel: () => void;
	onSave: (title: string, uniqueName: string) => Promise<void>;
	type: string;
}

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white",
			"&:hover": {
				color: "white",
			},
		},
	},
}));

const ConversationTitleModal: React.FC<ConversationTitleModalProps> = (
	props: ConversationTitleModalProps
) => {
	const conversations = useSelector((state: AppState) => state.convos);
	const classes = useStyles();
	const [title, setTitle] = useState(props.title);
	const [error, setError] = useState("");
	const [isFormDirty, setFormDirty] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [filteredActiveUsers, setFilteredActiveUsers] = useState<any>([]);
	const [selectedUser, setSelectedUser] = useState<any>();
	const [loader, setLoader] = useState(false);
	const user = useUser();
	useEffect(() => {
		if (props.title !== title) {
			setTitle(props.title);
		}
	}, [props.title]);

	const onCancel = () => {
		setError("");
		setFormDirty(false);
		setTitle(props.title);
		props.onCancel();
	};

	const isBadTitle = title.length < 1 || title.trim() === props.title;

	const onSave = async () => {
		if (selectedUser) {
			// return;
			setError("");

			//let title = selectedUser?.firstName+' '+selectedUser.lastName;
			let title = selectedUser?.name;
			let uniqueName = selectedUser?.email;
			try {
				await props.onSave(title, uniqueName);
			} catch (e) {
				setError((e as Error).message ?? "");
			}

			setLoading(false);
			props.onCancel();
			setTitle(props.title);
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLElement>) => {
		e.preventDefault();

		if (isBadTitle || isLoading) {
			return;
		}

		setLoading(true);
		onSave();
	};
	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (isLoading) {
			return;
		}

		if (e.key === "Escape") {
			e.preventDefault();
			onCancel();
		}
	};

	const handleAutocompleteChange = async (event: any, item: any) => {
		if (!item) {
			setSelectedUser(null); 
			return;
		}

		setError("");
		setSelectedUser(item);
	};

	const token = localStorage.getItem("sessionId");
	const getUserDetails = () => {
		fetch(
			// `${process.env.REACT_APP_BASE_URL}/api/active-users`,
			`${process.env.REACT_APP_BASE_URL}/api/user-connections/message/user-details`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					let filteredList: any = [];
					data
						.filter(
							(item: any) =>
								item?.user?.authorities
									.map((authority: { name: string }) => authority.name)
									.includes("ROLE_USER") &&
								(item?.userPrivacySettingsDTO?.messagePermission ===
									"Anyone can message me without being in connection" ||
									(item?.userPrivacySettingsDTO?.messagePermission ===
										"Only connections can send me message" &&
										item?.isConnected))
						)
						.map((auser: any) => {
							let comboUniqueName = [user.email, auser.user.email]
								.sort()
								.join("-");
							let check = conversations.find((e) => {
								return e.uniqueName && comboUniqueName === e.uniqueName;
							});
							if (!check) {
								//filteredList.push(auser.user);
								let name = ""; // Variable to hold the name value
								if (user?.userProfileId === auser?.id) {
									name = auser?.user?.firstName + " " + auser?.user?.lastName;
								} else if (
									auser?.userPrivacySettingsDTO?.identityPermission ===
									"Name is not visible. Instead just show nickname"
								) {
									name = auser?.nickname;
								} else if (
									auser?.userPrivacySettingsDTO?.identityPermission ===
									"Name is visible to all"
								) {
									name = auser?.user?.firstName + " " + auser?.user?.lastName;
								} else if (
									auser?.userPrivacySettingsDTO?.identityPermission ===
									"Name is visible only to my connections. Other see nickname"
								) {
									name =
										auser?.isConnected === true
											? auser?.user?.firstName + " " + auser?.user?.lastName
											: auser?.nickname;
								} else {
									name = auser?.user?.firstName + " " + auser?.user?.lastName;
								}
								filteredList.push({ ...auser.user, name });
							}
						});
					// const sortedOptions = options.sort((a, b) => a.localeCompare(b));
					//setFilteredActiveUsers(filteredList.sort((a: any, b: any) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName)));
					setFilteredActiveUsers(
						filteredList
						  .filter((value: any, index: number, self: any[]) =>
							index === self.findIndex((user: any) => user.name === value.name)
						  )
						  .sort((a: any, b: any) => a.name.localeCompare(b.name))
					  );
				} else {
					setFilteredActiveUsers([]);
				}
			})
			.catch((err) => {
				setFilteredActiveUsers([]);
			});
	};

	const filterOptions = (options: any, { inputValue }: any) => {
		return options.filter((option: any) =>
			option.name.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	useEffect(() => {
		getUserDetails();
	}, []);

	return (
		<>
			<ConvoModal
				title={props.type == "new" ? "New Conversation" : "Edit Conversation"}
				isModalOpen={props.isModalOpen}
				handleClose={onCancel}
				modalBody={
					<ModalBody>
						{/* <Box as="form" onSubmit={onSubmit} onKeyDown={onKeyDown}>
              <ModalInputField
                isFocused={true}
                label="Conversation name"
                input={title}
                placeholder="Enter conversation name"
                onChange={(s) => {
                  setTitle(s);
                  setFormDirty(s.length === 0);
                }}
                error={
                  error
                    ? error
                    : isFormDirty && !title
                    ? "Add a conversation title to create a conversation."
                    : ""
                }
              />
            </Box> */}

						{/* <Autocomplete
              onChange={handleAutocompleteChange}
              // multiple={false}
              placeholder="Create New Conversation"
              className="session-form"
              classes={classes}
              // disabled={formDisabled}
              options={filteredActiveUsers}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              filterSelectedOptions
              // defaultValue={_chips}
              // onInputChange={handleFilterChange}
              // onClick={createNewConversation}
              renderTags={(values, getTagProps) =>
                chips.map((value, index) => {
                  const { key, className, ...tagProps } = getTagProps({
                    index: index,
                  });
                  return (
                    <Chip
                      key={key}
                      avatar={
                        formDisabled ? (
                          <img src={DisabledProfile} alt="profile" />
                        ) : (
                          <img src={Profile} alt="profile" />
                        )
                      }
                      className={`${className} label-chip`}
                      label={`${value.firstName} ${value.lastName}`}
                      sx={{
                        backgroundColor: formDisabled
                          ? "customColor.gray"
                          : "customColor.blue",
                        fontStyle: theme.typography.subtitle2.fontStyle,
                        fontSize: theme.typography.subtitle2.fontSize,
                        lineHeight: theme.typography.subtitle2.lineHeight,
                        fontWeight: theme.typography.subtitle2.fontWeight,
                        color: formDisabled
                          ? "customColor.label"
                          : "secondary.main",
                      }}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={formDisabled ? "standard" : "outlined"}
                  label="Create New Conversation"
                />
              )}
              sx={{
                backgroundColor: formDisabled
                  ? "secondary"
                  : "customColor.gray1",
                color: "customColor.black",
              }}
            /> */}
						<Autocomplete
							// multiplec
							// id="multiple-limit-tags1"
							limitTags={1}
							options={filteredActiveUsers}
							// getOptionLabel={(option) => option.firstName+ ' '+option.lastName }
							getOptionLabel={(option) => option?.name}
							renderInput={(params) => (
								<TextField {...params} label="" placeholder="Search user" />
							)}
							filterOptions={filterOptions}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							)}
							// onChange={(event, selected) =>
							//   // updateSearchFilter("genres", newGenres)
							//   // handleAutocompleteChange(selected)
							// }
							onChange={handleAutocompleteChange}
						/>
					</ModalBody>
				}
				modalFooter={
					<ModalFooter>
						<ModalFooterActions>
							<Button
								disabled={isLoading}
								variant="secondary"
								onClick={onCancel}
							>
								Cancel
							</Button>
							<Button
								disabled={!selectedUser || isLoading}
								variant="primary"
								onClick={onSave}
							>
								{ActionName.Start}
							</Button>
						</ModalFooterActions>
					</ModalFooter>
				}
			/>
		</>
	);
};

export default ConversationTitleModal;
