import { useState, ChangeEvent, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	TextField,
	Button,
	Box,
	Paper,
	Grid,
	FormControl,
	FormHelperText,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Typography,
	useTheme,
	useMediaQuery,
	Snackbar,
	AlertTitle,
	Alert,
	Autocomplete,
} from "@mui/material";
import GoogleLocationService from "../components/GoogleLocationService";
import CheckIcon from "../images/check-fill.svg";
import CloseIcon from "../images/close-line.svg";
import SuccessMark from "../images/tick-circle.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";
import ErrorMark from "../images/danger.svg";
import LogoWengor from "../images/SignupLogo.svg";
import RightAarrow from "../images/right arrow.svg";
import { useGradient2Styles } from "../theme";
import i18n from "../l10n/strings.json";
import { Api } from "../utils/ApiCalls/Api";
import { SignUpProvider, useSignUp } from "../context/SignUpContext";
import ErrorAlert from "./Error/ErrorAlert";
import User from "../services/user";
import { useUser } from "../context/UserContext";
import SuccessIcon from "../images/tick-square.svg";
import ErrorIcon from "../images/danger.svg";
import useLocationAutocomplete from "../hooks/useLocationAutocomplete/useLocationAutocomplete";

type RouterState = {
	email: string;
};

type SignUpContextType = {
	Code: string;
	Password: string;
	Repassword: string;
	FirstName: string;
	LastName: string;
	Location: string;
};

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const initialUserData = {
	Code: "",
	Password: "",
	Repassword: "",
	FirstName: "",
	LastName: "",
	Location: "",
};

const SignUp = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const type: string = "";
	const { userData, setUserData } = useSignUp();
	const locationState = location.state as RouterState | undefined;
	const [firstName, setFirstName] = useState(userData.FirstName);
	const [lastName, setLastName] = useState(userData.LastName);
	const [email, setEmail] = useState<string>(locationState?.email as string);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState(userData.Password);
	const [repassword, setRepassword] = useState(userData.Repassword);
	const [code, setCode] = useState(userData.Code);
	const [selectedLocation, setSelectedLocation] = useState(userData.Location);
	const [dirtyPassword, setDirtyPassword] = useState(false);
	const [dirtyRepassword, setDirtyRepassword] = useState(false);
	const [dirtyFirstName, setDirtyFirstName] = useState(false);
	const [dirtyLastName, setDirtyLastName] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setErrorMessage] = useState(false);
	const [showRenewPassword, setShowRenewPassword] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showPassRequirement, setShowPassRequirements] = useState(false);
	const [confirmationMsg, setConfirmationMsg] = useState(
		i18n.signUp.confirmationCodeSentMsg
	);
	const [resendBtnDis, setResendBtnDis] = useState(false);
	const gradient = useGradient2Styles();
	//const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") !== -1;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAction, setShowAction] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [lat, setLat] = useState<Number>(0);
	const [longi, setLongi] = useState<Number>(0);

	useEffect(() => {
		setUserData(initialUserData);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setConfirmationMsg("");
		}, 3000);
	}, [confirmationMsg]);
	const expectedBaseURL = process.env.REACT_APP_BASE_URL_FRONTEND;

	useEffect(() => {
		const currentBaseURL = `${window.location.href.split("#")[0]}#`;
		if (currentBaseURL !== expectedBaseURL) {
			window.location.replace(`${expectedBaseURL}/login`);
			navigate("/login");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(user.nickname)}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if(response.status === 502){
				navigate('/defaultPage')
			}
			else if (response.status === 400) {
				ErrorAlert(response.status, setAlertStatus, setShowAlert, setAlertMsg);
			} else {
				const data = await response.json();
				if (data.privacySettings.userProfile.user.userProfileId !== 0) {
					setIsDisabled(true);
					setShowAction(true);
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Account already created");
				}
			}
		} catch (error) {
		}
	};

	const handleBrowserBack = () => {
		fetchData();
	};
	window.onpopstate = handleBrowserBack;

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const isEnable = () => {
		if (
			firstName.length > 0 &&
			firstName.length <= 50 &&
			lastName.length > 0 &&
			lastName.length <= 50 &&
			password.length >= 8 &&
			password.length <= 16 &&
			repassword.length >= 8 &&
			repassword.length <= 16 &&
			email.length > 0 &&
			selectedLocation.length > 0 &&
			code.length > 0 &&
			password === repassword &&
			UPPERCASEREGEX.test(password) &&
			NUMBEREREGEX.test(password) &&
			SPECIALCHAREREGEX.test(password)
		) {
			return false;
		} else {
			return true;
		}
	};

	const resetForm = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPassword("");
		setRepassword("");
		setCode("");
		setSelectedLocation("");
		setShowPassword(false);
		setShowRenewPassword(false);
		setDirtyPassword(false);
		setDirtyRepassword(false);
		setDirtyFirstName(false);
		setDirtyLastName(false);
		setIsError(false);
		setErrorMessage(false);
		setConfirmationMsg(i18n.signUp.confirmationCodeSentMsg);

		// Clear LocalStorage as well
		localStorage.removeItem(`signupFormData_${email}`);
	};

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					const result = {lat, lng};
          setLat(result.lat);
          setLongi(result.lng);
          return result;
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const handleSignUpSuccess = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/authenticate`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				username: email,
				password: password,
			}),
		})
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				} else if (response.status >= 400) {
					setIsError(true);
				} else {
					setIsError(false);
				}
				return response.json();
			})
			.then((result) => {
				if (result.id_token != null) {
					const token = result.id_token;
					localStorage.setItem("sessionId", token);

					resetForm();

					if (result.multifactorAuthEnabled) {
						navigate("/codeVerify", {
							state: {
								email: email,
							},
						});
					} else {
						navigate("/home");
					}
				} else {
					navigate("/login");
				}
			});
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const signup = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// getLocationLatLng(selectedLocation).then((result) => {
		// 	setLat(result.lat);
		// 	setLongi(result.lng);
		// });
		const userLang = document.documentElement.lang;
		let item = {
			otp: code,
			location: selectedLocation,
			firstName,
			lastName,
			email,
			login: email,
			password,
			langKey: userLang,
			latitude: lat,
			longitude: longi,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/register`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(item),
		})
			.then((response) => {
				if(response.status === 502) {
					navigate('/defaultPage')
				} else if (response.status >= 400) {
					setIsError(true);
					throw new Error();
				} else {
					return response;
				}
			})
			.then((result) => {
				setIsError(false);
				if (result) {
					handleSignUpSuccess();
				}
			})
			.catch((error) => {
				setIsError(true);
			});
	};

	const signUpMail = () => {
		// fetch(`${process.env.REACT_APP_BASE_URL}/api/generate-otp`, {
		// 	method: "POST",
		// 	// mode: "no-cors",
		// 	headers: { "Content-Type": "application/json" },
		// 	body: email,
		// })
		const otpDTO = {
			userEmail: email,
			otpType: "RESEND_OTP",
		};
		Api.callService
			.fetchApi(
				"POST",
				"generate-otp",
				null,
				JSON.stringify(otpDTO),
				true,
				false
			)
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.status >= 400) {
					setErrorMessage(true);
				} else {
					setErrorMessage(false);
					setConfirmationMsg(i18n.signUp.reconfirmationCodeSentMsg);
					navigate("/sign-up", { state: { email: email } });
				}
			});
	};

	const validateOtp = () => {

		let item = {
			otp: code,
			userEmail: email,
		};
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/validate-otp-signup`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(item),
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				const token = result.id_token;
				localStorage.setItem("sessionId", token);
				// if (result.status === "BAD_REQUEST") {
				// 	setIsError(true);
				// }
				if(result.status === 502){
					navigate('/defaultPage')
				}
				else {
					setErrorMessage(false);
					const data: SignUpContextType = {
						Code: code,
						Password: password,
						Repassword: repassword,
						FirstName: firstName,
						LastName: lastName,
						Location: selectedLocation,
					};

					setUserData(data);

					let formData = new FormData();
					formData.append("firstName", firstName);
					formData.append("lastName", lastName);
					formData.append("email", email);
					formData.append("login", email);
					formData.append("password", password);
					formData.append("location", selectedLocation);
					formData.append("otp", code);
					formData.append("type", type);
					formData.append("latitude", lat.toLocaleString());
					formData.append("longitude", longi.toLocaleString());

					fetch(`${process.env.REACT_APP_BASE_URL}/api/social-sign-up`, {
						method: "POST",
						body: formData,
					})
						.then((res) => {
							if(res.status === 502){
								navigate('/defaultPage')
							} else if (res.status !== 200 && res.status !== 201) {
								ErrorAlert(
									res.status,
									setAlertStatus,
									setShowAlert,
									setAlertMsg
								);
							} else {
								return res.json();
							}
						})
						.then((data) => {
							if (data) {
								let data = {
									username: email,
									password: "password",
									loginType: "social",
								};

								fetch(`${process.env.REACT_APP_BASE_URL}/api/new-login`, {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((response) => {
										if (response.status >= 400) {
											navigate("/login");
										} else {
											return response.json();
										}
									})
									.then((result) => {
										if (result.errorKey === "accountDeactivated") {
											navigate("/login");
										}
										if (result.id_token != null && result.loginIdentifier) {
											if (!result.isAlreadyLoggedIn) {
												const token = result.id_token;
												localStorage.setItem("sessionId", token);

												User.fetchUserInfo(() => {});
												navigate("/socialProfile", {
													state: {
														firstName: firstName,
														lastName: lastName,
														email,
														login: email,
														otp: code,
														location: selectedLocation,
														password: password,
														type: type,
													},
												});
												resetForm();
											}
										}
									});
							}
						});
				}
			});
	};


	const handleKeyDown = (event: any) => {
		// Prevent typing space in the password field
		if (event.key === " ") {
			event.preventDefault();
		}
	};

	useEffect(()=>{
		getLocationLatLng(selectedLocation).then((result)=>{
			console.log("executed");
			setLat(result.lat);
			setLongi(result.lng);
		  })
	},[selectedLocation])

	const handleResendCode = () => {
		setTimeout(() => {
			setResendBtnDis(false);
		}, 3000);
		signUpMail();
	};

	//code field validation
	const exceptThisSymbols = ["e", "E", "+", "-", "."];
	return (
		// onSubmit={signUp}
		<SignUpProvider>
			{showAlert && (
				<Snackbar
					sx={{
						"&.MuiSnackbar-anchorOriginTopCenter1": {
							top: "70px",
						},
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showAlert}
					autoHideDuration={6000}
					onClose={handleAlertClose}
				>
					<Alert
						onClose={handleAlertClose}
						icon={
							alertStatus ? (
								<img src={SuccessIcon} alt="error-icon" />
							) : (
								<img
									src={ErrorIcon}
									alt="error-icon"
									style={{ marginRight: "10px" }}
								/>
							)
						}
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "6px",
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "black",
						}}
					>
						<AlertTitle
							sx={{
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "#000000",
								display: "flex",
								margin: "auto",
								padding: "5px",
								justifyContent: "center !important",
							}}
						>
							{alertMsg}
						</AlertTitle>
					</Alert>
				</Snackbar>
			)}
			<form autoComplete="off" noValidate>
				<div
					className="column"
					style={{
						justifyContent: "center",
						margin: "0% 0% 2% 0%",
					}}
				>
					<div className="column " style={{ flex: 1 }}>
						<div className="row" style={{ padding: "24px 24px 14px 24px" }}>
							<img src={LogoWengor} alt="LuteFish Logo"></img>
							<br />
						</div>
					</div>
					<div
						className="heading-name"
						style={{
							marginBottom: confirmationMsg === "" ? "20px" : "default",
							marginTop: "-40px",
						}}
					>
						<Typography variant="h1">{i18n.signUp.title}</Typography>
					</div>
					{confirmationMsg !== "" && confirmationMsg.length > 0 ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
								marginBottom: "20px",
							}}
						>
							<div style={{ marginRight: "10px" }}>
								<img src={SuccessMark} alt="success-mark" />
							</div>
							<Typography variant="h6" sx={{ color: "success.main" }}>
								{confirmationMsg}
							</Typography>
						</div>
					) : (
						""
					)}

					<div className="signupform">
						<Grid
							item
							xs={10}
							sm={7}
							md={5}
							component={Paper}
							elevation={5}
							square
							sx={{
								scrollbarWidth: "none",
								overflowY: "auto",
							}}
						>
							<Box
								sx={{
									marginTop: "37px",
									marginBottom: 2,
									width: 600,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<FormControl sx={{ width: "392px" }}>
									<Grid xs={12} mb={"20px"} mt={0}>
										<Box my={0.5} mb={1}>
											<FormHelperText
												className="outlined-weight-helper-text"
												sx={{ marginLeft: "0px !important" }}
											>
												<Box display="flex" justifyContent="space-between">
													<Typography
														variant="subtitle1"
														sx={{ color: "customColor.label" }}
													>
														Email Confirmation Code
														<span style={{ color: "red" }}>*</span>
													</Typography>
													<Button
														onClick={() => {
															setResendBtnDis(true);
															handleResendCode();
														}}
														disabled={resendBtnDis}
														disableRipple
														sx={{
															background: "transparent",
															padding: "0px !important",
															mt: -1,
															"&:hover": {
																backgroundColor: "transparent",
															},
														}}
													>
														<Typography
															sx={{
																color: resendBtnDis
																	? "#888888"
																	: "customColor.link",
																fontWeight: 400,
																fontSize: "14px",
																lineHeight: "14px",
															}}
														>
															Resend Code
														</Typography>
													</Button>
												</Box>
											</FormHelperText>
											<div>
												{error && (
													<div
														style={{
															display: "flex",
															alignContent: "center",
															alignItems: "center",
															textAlign: "center",
														}}
													>
														<div style={{ marginRight: "10px" }}>
															<img src={ErrorMark} alt="error-mark" />
														</div>
														<Typography
															variant="subtitle1"
															color="success.main"
														>
															Account with email exists.
														</Typography>
														<a
															href="/#/login"
															style={{
																fontFamily: "Avenir",
																fontStyle: "normal",
																fontSize: "16px",
																color: "#0A6BFE",
																margin: "0 5px",
															}}
														>
															<Typography
																variant="subtitle1"
																color="success.link"
															>
																Sign In I
															</Typography>
														</a>
														<Typography
															variant="subtitle1"
															color="customColor.black"
														>
															Or{" "}
														</Typography>
														<a
															href="#/forgotPassword"
															style={{
																fontFamily: "Avenir",
																fontStyle: "normal",
																fontSize: "16px",
																color: "#0A6BFE",
																margin: "0 5px",
															}}
														>
															<Typography
																variant="subtitle1"
																color="success.link"
															>
																Reset Password
															</Typography>
														</a>
													</div>
												)}
											</div>
										</Box>
										<Box my={0.5} mb={"12.01px"}>
											<TextField
												id="code"
												value={code}
												type="text"
												error={isError || code.length > 6}
												inputProps={{
													style: {
														background: "customColor.gray1",
														borderColor: "customColor.gray2",
														color: "customColor.black",
														width: "364px",
													},
													"aria-autocomplete": "none",
													min: 0,
												}}
												onKeyDown={(e) =>
													exceptThisSymbols.includes(e.key) &&
													e.preventDefault()
												}
												onChange={(e) => {
													setIsError(false);
													// Handle numeric input validation manually
													const inputValue = e.target.value;
													if (
														/^\d*$/.test(inputValue) &&
														inputValue.length <= 6
													) {
														setCode(inputValue);
													}
												}}
												variant="outlined"
												required
												autoFocus
												autoComplete="nope"
											/>
											{(isError || code.length > 6) && (
												<FormHelperText
													sx={{
														color: "error.light",
														textAlign: "right",
														fontSize: "13px",
													}}
												>
													{"Code not valid"}
												</FormHelperText>
											)}
										</Box>
									</Grid>
									<Box my={0.5} mb={"20px"} mt={0}>
										<Grid xs={12}>
											<FormHelperText
												className="outlined-weight-helper-text"
												sx={{ marginLeft: "0px !important" }}
											>
												<Box display="flex" justifyContent={"space-between"}>
													<Typography
														variant="subtitle1"
														sx={{ color: "customColor.label" }}
													>
														Email<span style={{ color: "red" }}>*</span>
													</Typography>
													<a href="/#/signUpEmail">
														<Typography
															sx={{
																color: "customColor.link",
																fontWeight: 400,
																fontSize: "14px",
																lineHeight: "14px",
															}}
														>
															Wrong Email?
														</Typography>
													</a>
												</Box>
											</FormHelperText>
											{locationState?.email && (
												<TextField
													id="email"
													type="email"
													value={locationState?.email}
													onChange={(
														event: ChangeEvent<
															HTMLTextAreaElement | HTMLInputElement
														>
													) => setEmail(event.target.value)}
													inputProps={{
														style: {
															color: "customColor.gray4",
															fontSize: "16px",
															width: "364px",
															height: 60,
															paddingLeft: 12,
														},
													}}
													variant="standard"
													disabled
													required
												/>
											)}
										</Grid>
									</Box>
									<Box my={0.5} mb={"20px"} mt={0}>
										<FormHelperText
											className="outlined-weight-helper-text"
											sx={{ mb: "9px", ml: "0px !important" }}
										>
											<Typography
												variant="subtitle1"
												sx={{ color: "customColor.label" }}
											>
												Password<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<OutlinedInput
											id="outlined-adornment-password"
											type={showPassword ? "text" : "password"}
											sx={{
												background: "#F8F8F8",
												borderColor: "customColor.gray2",
												color: "customColor.black",
												width: "100%",
												height: "42px",
											}}
											inputProps={{
												style: {
													height: 23,
												},
											}}
											onKeyDown={handleKeyDown}
											onChange={(e) => setPassword(e.target.value)}
											onFocus={() => {
												setDirtyPassword(true);
												setShowPassRequirements(true);
											}}
											onBlur={() => setShowPassRequirements(false)}
											onCopy={(e) => e.preventDefault()}
											onPaste={(e) => e.preventDefault()}
											onCut={(e) => e.preventDefault()}
											error={
												dirtyPassword &&
												(password.length < 8 ||
													password.length > 16 ||
													!UPPERCASEREGEX.test(password) ||
													!NUMBEREREGEX.test(password) ||
													!SPECIALCHAREREGEX.test(password))
											}
											value={password}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((show) => !show)}
														onMouseDown={(
															event: React.MouseEvent<HTMLButtonElement>
														) => event.preventDefault()}
														edge="end"
													>
														{showPassword ? (
															<img src={VisibleOn} alt="eye" />
														) : (
															<img src={VisibleOff} alt="Eye Slash" />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
										{dirtyPassword &&
											(password.length < 8 || password.length > 16) && (
												<FormHelperText
													sx={{
														textAlign: "right",
														fontSize: "13px",
													}}
												>
													<Typography
														variant="body2"
														sx={{ color: "error.light" }}
													>
														{password.length <= 0
															? "Include at least one special character & number"
															: password.length < 8 || password.length > 16
															? "Enter 8-16 character"
															: "Include at least one special character & number"}
													</Typography>
												</FormHelperText>
											)}
									</Box>
									<Box my={0.5} mb={"20px"} mt={0}>
										<FormHelperText
											sx={{ marginLeft: "0px !important", mb: "9.74px" }}
										>
											<Typography
												variant="subtitle1"
												sx={{ color: "customColor.label" }}
											>
												Retype Password<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<OutlinedInput
											id="outlined-adornment-password"
											type={showRenewPassword ? "text" : "password"}
											sx={{
												background: "#F8F8F8",
												borderColor: "customColor.gray2",
												color: "customColor.black",
												width: "100%",
												height: "41.3px",
											}}
											inputProps={{
												style: {
													height: 23,
												},
											}}
											onKeyDown={handleKeyDown}
											onChange={(e) => setRepassword(e.target.value)}
											onFocus={() => setDirtyRepassword(true)}
											onCopy={(e) => e.preventDefault()}
											onPaste={(e) => e.preventDefault()}
											onCut={(e) => e.preventDefault()}
											error={
												dirtyRepassword &&
												(repassword.length < 8 || repassword.length > 16)
											}
											value={repassword}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() =>
															setShowRenewPassword((show) => !show)
														}
														onMouseDown={(
															event: React.MouseEvent<HTMLButtonElement>
														) => event.preventDefault()}
														edge="end"
													>
														{showRenewPassword ? (
															<img src={VisibleOn} alt="eye" />
														) : (
															<img src={VisibleOff} alt="Eye Slash" />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
										{dirtyRepassword && (
											<FormHelperText
												sx={{
													textAlign: "right",
												}}
											>
												<Typography
													variant="body2"
													sx={{ color: "error.light" }}
												>
													{dirtyRepassword && !repassword
														? "Re-enter password"
														: password !== repassword
														? "Password does not match"
														: ""}
												</Typography>
											</FormHelperText>
										)}
									</Box>
									<Box
										style={{
											display: "flex",
											justifyContent: "space-between",
											flexDirection: "row",
											width: "100%",
										}}
										my={0.5}
										mt={0}
									>
										<div style={{ width: "184px", marginBottom: "17px" }}>
											<FormHelperText className="outerlabel" sx={{ mb: "9px" }}>
												<Typography
													variant="subtitle1"
													sx={{ color: "customColor.label" }}
												>
													First Name<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<TextField
												id="firstname"
												error={
													dirtyFirstName &&
													(firstName?.length <= 0 ||
														firstName?.length > 50 ||
														NUMBEREREGEX.test(firstName) ||
														SPECIALCHAREREGEX.test(firstName))
												}
												value={firstName}
												onFocus={() => setDirtyFirstName(true)}
												inputProps={{
													style: {
														background: "#F8F8F8",
														borderColor: "customColor.gray2",
														color: "customColor.black",
														width: "156px",
														height: 23,
													},
												}}
												onChange={(e) => setFirstName(e.target.value.trim())}
												variant="outlined"
												required
											/>
											{dirtyFirstName && (
												<FormHelperText
													sx={{
														textAlign: "right",
														marginRight: "5px",
														marginLeft: "0px",
													}}
												>
													<Typography
														variant="body2"
														sx={{ color: "error.light" }}
													>
														{firstName?.length <= 0
															? "Please enter First Name"
															: firstName?.length > 50 ||
															  NUMBEREREGEX.test(firstName) ||
															  SPECIALCHAREREGEX.test(firstName)
															? "Please enter valid First Name"
															: ""}
													</Typography>
												</FormHelperText>
											)}
										</div>
										<div style={{ width: "184px" }}>
											<FormHelperText
												className="outerlabel"
												sx={{ mb: "9.74px" }}
											>
												<Typography
													variant="subtitle1"
													sx={{ color: "customColor.label" }}
												>
													Last Name<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<TextField
												id="lastname"
												type="text"
												value={lastName}
												error={
													dirtyLastName &&
													(lastName?.length <= 0 ||
														lastName?.length > 50 ||
														NUMBEREREGEX.test(lastName) ||
														SPECIALCHAREREGEX.test(lastName))
												}
												onChange={(e) => setLastName(e.target.value.trim())}
												onFocus={() => setDirtyLastName(true)}
												inputProps={{
													style: {
														background: "#F8F8F8",
														borderColor: "customColor.gray2",
														color: "customColor.black",
														width: "156px",
														height: 23,
													},
												}}
												variant="outlined"
												required
											/>
											{dirtyLastName && (
												<FormHelperText
													sx={{
														textAlign: "right",
														marginRight: "5px",
														marginLeft: "0px",
													}}
												>
													<Typography
														variant="body2"
														sx={{ color: "error.light" }}
													>
														{dirtyLastName
															? lastName?.length <= 0
																? "Please enter Last Name"
																: lastName?.length > 50 ||
																  NUMBEREREGEX.test(lastName) ||
																  SPECIALCHAREREGEX.test(lastName)
																? "Please enter valid Last Name"
																: ""
															: ""}
													</Typography>
												</FormHelperText>
											)}
										</div>
									</Box>
									{/* <div style={{ margin: "10px 0px" }}>
										<Typography
											sx={{
												fontSize: "11.2px",
												fontWeight: 350,
												lineHeight: "14.4px",
												color: "#7A7985",
												fontFamily: "Avenir",
											}}
										>
											@nickname will be auto generated for you. Change it in
											Settings later
										</Typography>
									</div> */}
									<Box my={0.5}>
										<div style={{ color: "#707081", padding: "20px 0", marginTop: "-12px" }}>
											<Typography
												variant="body2"
												sx={{ color: "customColor.text" }}
											>
												@nickname will be auto generated for you. Change it in
												Settings later
											</Typography>
										</div>
										<FormHelperText
											className="outlined-weight-helper-text"
											sx={{ marginLeft: 0 }}
										>
											<Typography
												variant="subtitle1"
												sx={{ color: "customColor.label" }}
											>
												Location<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<GoogleLocationService
											defaultValue={selectedLocation}
											onLocationChange={(locationDesc: string) =>
												setSelectedLocation(locationDesc)
											}
										/>
									</Box>
									<Box my={3}>
										{showAction ? (
											<Button
												sx={{ borderRadius: "24px", color: "gray" }}
												endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												fullWidth
												size="large"
												disabled={isDisabled}
												variant="contained"
											>
												<Typography>{i18n.signUp.next}</Typography>
											</Button>
										) : (
											<Button
												className={`submit-button ${gradient.gradient2}`}
												sx={{ borderRadius: "24px" }}
												//type='submit'
												endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												fullWidth
												size="large"
												disabled={isEnable()}
												variant="contained"
												onClick={validateOtp}
											>
												<Typography>{i18n.signUp.next}</Typography>
											</Button>
										)}
									</Box>
								</FormControl>
							</Box>
						</Grid>
					</div>
					{/* {
					showPassRequirement && dirtyPassword && (  */}
					<div className="password-guidelines">
						<Typography variant="subtitle1" sx={{ color: "customColor.text" }}>
							Password requirements
						</Typography>
						<div className="password-validate">
							{password.length >= 8 ? (
								<img src={CheckIcon} alt="Check" />
							) : (
								<img src={CloseIcon} alt="Close" />
							)}
							<Typography variant="body4" sx={{ color: "customColor.text" }}>
								At least 8 characters
							</Typography>
						</div>
						<div className="password-validate">
							{UPPERCASEREGEX.test(password) ? (
								<img src={CheckIcon} alt="Check" />
							) : (
								<img src={CloseIcon} alt="Close" />
							)}
							<Typography variant="body4" sx={{ color: "customColor.text" }}>
								One capital letter
							</Typography>
						</div>
						<div className="password-validate">
							{NUMBEREREGEX.test(password) ? (
								<img src={CheckIcon} alt="Check" />
							) : (
								<img src={CloseIcon} alt="Close" />
							)}
							<Typography variant="body4" sx={{ color: "customColor.text" }}>
								One number
							</Typography>
						</div>
						<div className="password-validate">
							{SPECIALCHAREREGEX.test(password) ? (
								<img src={CheckIcon} alt="Check" />
							) : (
								<img src={CloseIcon} alt="Close" />
							)}
							<Typography variant="body4" sx={{ color: "customColor.text" }}>
								One special character !@#%$&
							</Typography>
						</div>
					</div>
					{/* )
				}  */}
				</div>
			</form>
		</SignUpProvider>
	);
};

export default SignUp;
