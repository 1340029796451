import { useEffect, useState } from "react";
import {
	Typography,
	Card,
	CardContent,
	CardActions,
	Button,
	Alert,
	AlertTitle,
	CardHeader,
	Snackbar,
	Grid,
	CardMedia,
	Dialog,
	DialogTitle,
	DialogContent,
	FormControl,
	TextField,
	FormHelperText,
	DialogActions,
	Popover,
} from "@mui/material";
import CenterCircularProgress from "./CenterCircularProgress";
// images
import i18n from "../l10n/strings.json";
import Avatar from "../images/avatar.svg";
import LandingPage from "../images/landing-page-new-device.svg";
import RightAarrow from "../images/right arrow.svg";
import ErrorIcon from "../images/danger.svg";
import SuccessIcon from "../images/tick-square.svg";
// contexts
import { useUser } from "../context/UserContext";
// types
import { DeviceInterface } from "../types/types";

// components
import AddDeviceDialog from "../dialogBox/AddDeviceDialog";
import HelpAndTips from "../pages/HelpAndTips";
import Sessions from "./jam-session/Sessions";
import ScheduleSession from "./jam-session/scheduleSession";
import { useNavigate, useParams } from "react-router-dom";
import HomeSideBar from "./commonComponents/HomeSideBar";
import PostsHome from "./Posts/PostsHome";
// import AdsComponent from "./commonComponents/AdsComponent";
import { addDays, addMinutes, isAfter, isBefore, set } from "date-fns";
import LeftPanel from "./commonComponents/LeftPanel";
import SettingsAlert from "../pages/SettingsAlert";
import MyActivitiesSidePanel from "./Social/myActivitiesSidePanel";
import { EventsSidePanel } from "./commonComponents/EventsSidePanel";
import { useAlertStatus } from "../context/AlertStatusContext";
import validator from "validator";
import { Box } from "@mui/system";
import closeIcon from "../images/close.svg";
import { Email } from "@mui/icons-material";
import { useSignUp } from "../context/SignUpContext";
import CreateTicket from "./HelpAndSupport/CreateTicket";
import Loader from "./commonComponents/Loader";
import SocialProfBar from "./SocialProfBar";
import User from "../services/user";
import useAdminRedirect from "../hooks/useAdminRedirect/useAdminRedirect";
import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import { CompatibilityPopup } from "../dialogBox/CompatibilityPopup";

const Home = () => {
	useAdminRedirect();
	const { userData, setUserData } = useSignUp();
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [usersData, setUsersData] = useState<any>({});
	const [activeDevice, setActiveDevice] = useState<DeviceInterface | null>(
		null
	);

	// const [openSession, setOpenSessions] = useState(false);
	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [openScheduleSession, setOpenScheduleSession] = useState(false);
	const [cardContainerHeight, setCardContainerheight] = useState<
		number | undefined
	>(0);
	const [loading, setLoading] = useState(true);
	const [sessions, setSessions] = useState<any>([]);

	const [inviteFriendDialogOpen, setInviteFriendDialogOpen] = useState(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [existingEmails, setExistingEmails] = useState<string[]>([]);
	const [newEmails, setNewEmails] = useState<
		{ email: string; err: string; sent: boolean }[]
	>([]);
	const [remainingCount, setRemainingCount] = useState<number>(0);
	const { updateAlertStatus } = useAlertStatus();
	const [dialogSubmitBtn, setDialogSubmitBtn] = useState<boolean>(false);
	const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
	const [countText, setCountText] = useState<any>();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		let user = localStorage.getItem("user");
		if (user) {
			try {
				const userDetails = JSON.parse(user);
				if (
					(!userDetails.firstName ||
						!userDetails.lastName ||
						!userDetails.location) &&
					!isAdmin
				) {
					navigate("/socialProfileNew", {
						state: {
							email: userDetails.email,
							password: "",
						},
					});
				}
			} catch (e) {
				console.error("Failed to parse user data:", e);
			}
		}
	}, [navigate]);

	useEffect(() => {
		remainingCount === 0
			? setCountText("coming soon")
			: setCountText(`${remainingCount} remaining`);
	}, [remainingCount]);

	const emailSetup = () => {
		try {
			setNewEmails([]);
			setExistingEmails([]);

			const bodyData: any = {
				userEmail: user.email,
			};

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/code-generations/invite-friends`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(bodyData),
				}
			)
				.then((res) => {
					if (res.status >= 400) {
						throw new Error("api_error");
					}
					return res.json();
				})
				.then((data) => {
					const newEmailArray = data
						.filter((item: any) => item.userEmail === "None")
						.map(() => ({ email: "", err: "", sent: false }));

					const existingEmailArray = data
						.filter((item: any) => item.userEmail !== "None")
						.map((item: any) => item.userEmail);

					// Update state directly
					setNewEmails(newEmailArray);
					setExistingEmails(existingEmailArray);
					setRemainingCount(newEmailArray.length);
				})
				.catch((err) => {});
		} catch (error) {}
	};
	useEffect(() => {
		emailSetup();
	}, []);

	useEffect(() => {
		const filter = "agenda";
		const sDate = set(new Date(new Date()), {
			hours: 0,
			minutes: 0,
			seconds: 0,
		});
		let eDate = null;
		// if (filter === "agenda") {
		eDate = new Date(
			sDate.getFullYear(),
			sDate.getMonth(),
			sDate.getDate(),
			23,
			59,
			59
		);
		// } else if (props.filter === "week") {
		// 	eDate = addDays(
		// 		set(new Date(sDate), { hours: 23, minutes: 59, seconds: 59 }),
		// 		6
		// 	);
		// } else if (props.filter === "month") {
		// 	eDate = new Date(
		// 		sDate.getFullYear(),
		// 		sDate.getMonth() + 1,
		// 		0,
		// 		23,
		// 		59,
		// 		59
		// 	);
		// }
		// setSessions([]);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-sessions`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userId: user.id,
				groupBy: filter,
				startDate: sDate,
				endDate: eDate,
			}),
		})
			.then((res) => {
				if (res.status === 502) {
					navigate("/defaultPage");
				} else if (res.status > 400) {
					if (res.status === 401) {
						throw new Error(
							"You are not authenticated. Please log in to continue."
						);
					}
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setSessions([...data]);
				setTimeout(() => {
					setLoading(false);
				}, 2000);
				// if (props.autoJoinSID) {
				// 	autoJoinSession(Number(props.autoJoinSID), data);
				// }
			});
	}, []);

	useEffect(() => {
		const alertElement = document.getElementById("home-alert-title-bar");
		const alertRect = alertElement?.getBoundingClientRect();

		const homeTileElement = document.getElementById("home-header-title-bar");
		const homeTitleRect = homeTileElement?.getBoundingClientRect();
		if (
			alertRect?.height !== undefined &&
			homeTitleRect?.height !== undefined
		) {
			setCardContainerheight(alertRect?.height + homeTitleRect?.height);
		}
	}, []);

	// const getActiveDevice = async () => {
	// 	fetch(`${process.env.REACT_APP_BASE_URL}/api/user-devices/${user.id}`, {
	// 		method: "GET",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: `Bearer ${token}`,
	// 		},
	// 	})
	// 		.then((res) => {
	// 			if (res.status > 400) {
	// 				throw new Error("api_error");
	// 			} else {
	// 				return res.json();
	// 			}
	// 		})
	// 		.then((data) => {
	// 			if (data !== null) {
	// 				setActiveDevice(data);
	// 				// getActiveUsers();
	// 			} else {
	// 				setActiveDevice(null);
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			setActiveDevice(null);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };
	const fetchUserInfo = () => {
		setIsLoading(true);
		fetch(
			`${
				process.env.REACT_APP_BASE_URL
			}/api/view-user-profile/${encodeURIComponent(user?.nickname)}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				if (response.status === 502) {
					navigate("/defaultPage");
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				if (
					result.privacySettings.userProfile.multifactorAuthEnabled == true &&
					localStorage.getItem("isVerified") == "false"
				) {
					navigate("/codeVerify");
				}
				setIsLoading(false);
				if (result !== null) {
					setUsersData(result);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		// api call to fetch user data
		setTimeout(() => {}, 3000);
		if (user?.nickname) {
			fetchUserInfo();
		}

		// getActiveDevice();
		setIsLoading(true);
	}, [user]);

	const handleSuccess = () => {
		navigate(`/home/settings/deviceSetup/device`);
		setAlertMsg(i18n.settings.deviceSetup.success);
		setShowAlert(true);
		setAlertStatus(true);
		// getActiveDevice();
		setOpen(false);
	};
	const updateAlertMsg = (showErr: boolean, msg: string) => {
		setAlertMsg(msg);
		setShowAlert(showErr);
		setAlertStatus(false);
		// getActiveDevice();
		setOpen(false);
	};
	const setAlertOnEditSession = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setShowAlert(_showAlert);
		setAlertStatus(_alertStatus);
		setAlertMsg(_alertMsg);
	};

	const handleEditSessionClose = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setOpenScheduleSession(false);
		if (_showAlert && _alertStatus && _alertMsg) {
			setShowAlert(_showAlert);
			setAlertStatus(_alertStatus);
			setAlertMsg(_alertMsg);
		}
	};

	const handleDialogClose = () => {
		setFeedbackOpen(false);
	};

	if (loading) {
		return <Loader />;
	}
	if (isLoading) {
		return <Loader />;
	}

	const handleInviteBtnClick = () => {
		setIsDisabled(true);
		setDialogSubmitBtn(false);
		setInviteFriendDialogOpen(true);
	};

	const preorderStream = () => {
		window.open("https://lutefish.com/products/lutefish", "_blank");
	};

	const handleSendInvitationClick = () => {
		setNewEmails((prevEmails) =>
			prevEmails.map((item) => {
				if (item.email !== "") {
					if (!validator.isEmail(item.email)) {
						return { ...item, err: "Invalid email format", sent: false };
					} else if (
						existingEmails.find(
							(existingEmail) =>
								existingEmail.toLowerCase() === item.email.toLowerCase()
						)
					) {
						return { ...item, err: "already invited by you", sent: false };
					} else if (
						newEmails.filter(
							(emailObj) =>
								emailObj.email.toLowerCase() === item.email.toLowerCase()
						).length > 1
					) {
						return { ...item, err: "Cannot enter multiple times", sent: false };
					} else if (item.email.toLowerCase() === user.email.toLowerCase()) {
						return {
							...item,
							err: "you can not invite your self",
							sent: false,
						};
					}
				}
				return item;
			})
		);

		let flag = true;
		newEmails.map((item) => {
			if (
				(item.email !== "" && !validator.isEmail(item.email)) ||
				existingEmails.find(
					(existingEmail) =>
						existingEmail.toLowerCase() === item.email.toLowerCase()
				) ||
				item.email.toLowerCase() === user.email.toLowerCase()
			) {
				flag = false;
				return 0;
			} else if (
				item.email !== "" &&
				newEmails.filter(
					(emailObj) =>
						emailObj.email.toLowerCase() === item.email.toLowerCase()
				).length > 1
			) {
				flag = false;
			}
		});

		if (!flag) {
			setDialogSubmitBtn(false);
			return;
		}

		const finalEmails = newEmails
			.filter(
				(item) =>
					item.email !== "" &&
					!item.sent &&
					validator.isEmail(item.email) &&
					!existingEmails.includes(item.email)
			)
			.map((item) => item.email);
		const bodyData: any = {
			userEmail: user.email,
			invitedUserEmail: finalEmails,
			firstName: user.firstName,
			lastName: user.lastName,
		};
		if (finalEmails.length > 0) {
			try {
				fetch(
					`${process.env.REACT_APP_BASE_URL}/api/code-generations/send-invitation-to-friends`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(bodyData),
					}
				)
					.then((response) => {
						if (response.status > 400) {
							setDialogSubmitBtn(false);
							if (response.status === 409) {
								updateAlertStatus(
									true,
									false,
									"One or more email already invited by someone!"
								);
							} else {
								updateAlertStatus(
									true,
									false,
									"Unable to sent invitation right now!"
								);
							}
						}

						return response.json();
					})
					.then((data) => {
						if (data.userResponses && data.userResponses.length > 0) {
							let emailsCopy: any = [...newEmails];
							data.userResponses.map((item: any) => {
								if (item.errorKey === "userAlreadyExists") {
									emailsCopy = emailsCopy.map((emailObj: any) => {
										if (emailObj.email === item.userEmail) {
											return {
												email: emailObj.email,
												err: "already invited by someone",
												sent: false,
											};
										} else if (emailObj.email !== "") {
											return {
												email: emailObj.email,
												err: "",
												sent: true,
											};
										}
										return { ...emailObj };
									});
								}
							});
							setNewEmails(emailsCopy);
							setDialogSubmitBtn(false);
							return;
						} else if (data.userResponses && data.userResponses.length === 0) {
							emailSetup();
							setInviteFriendDialogOpen(false);
							setDialogSubmitBtn(false);
							setIsDisabled(false);
							updateAlertStatus(true, true, "Invitation sent successfully");
						}
					});
			} catch (error) {
				setDialogSubmitBtn(false);
			}
		} else {
			setDialogSubmitBtn(false);
			updateAlertStatus(true, false, "Please enter atleast one valid email");
		}
	};

	const handleNewEmailChange = (index: number, newEmail: string) => {
		setNewEmails((prevEmails) => {
			const newEmailsCopy = [...prevEmails];
			newEmailsCopy[index] = { email: newEmail, err: "", sent: false };
			return newEmailsCopy;
		});
	};

	const showSocialProfBar =
		!usersData ||
		!usersData.instrumentsList ||
		usersData.instrumentsList.length === 0 ||
		!usersData.privacySettings ||
		!usersData.privacySettings.userProfile ||
		!usersData.privacySettings.userProfile.genres ||
		usersData.privacySettings.userProfile.genres === "";

	const handleDailogClose = () => {
		setInviteFriendDialogOpen(false);
		setIsDisabled(false);

		emailSetup();
	};

	return (
		<>
			<CompatibilityPopup />
			{showSocialProfBar && <SocialProfBar />}
			<SettingsAlert />
			<Grid
				className="center-align homeTab"
				style={{ padding: "10px", position: "relative" }}
				id="home-alert-title-bar"
			>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={() => setShowAlert(false)}
					>
						<Alert
							onClose={() => setShowAlert(false)}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",

									fontWeight: "500",
									color: "#000000",
									display: "flex",

									margin: "auto",
									padding: "4px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Grid>

			<div className="mainPanel" style={{ margin: "auto" }}>
				<Grid container spacing={0}>
					<Grid item xs={12} container className="mainPanel">
						<Grid item xs={9.1} sx={{ mt: 2 }}>
							<Typography
								className="home-title-custom-size"
								sx={{
									fontWeight: 500,
									color: "#000000",
									fontSize: "2.25rem",
									paddingLeft: "15px",
								}}
							>
								{i18n.home.Heading}
							</Typography>
						</Grid>
						{/* Hiding schedule session btn for social-site launch */}
						<Grid
							direction={"column"}
							container
							xs={2.9}
							sx={{
								mt: 4,
								display: "flex !important",
								justifyContent: "flex-end !important",
							}}
							className="scheduleBtn"
						>
							{
								// user.isCollaborationActive === true &&
								activeDevice !== null ? (
									<Button
										className="submit-button button-field-custom-font-size"
										size="large"
										sx={{ width: "100%", fontSize: "18px !important" }}
										fullWidth
										variant="contained"
										onClick={() => setOpenScheduleSession(true)}
										disabled={user.userAccountStatus === "SUSPEND"}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									>
										<Typography>{i18n.jamSession.schedule}</Typography>
									</Button>
								) : (
									""
								)
							}
							{/* <Grid item={true}
								sx={{ display: "flex", justifyContent: "center", mt: "-1rem" }}
							>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontSize: "14px",
										fontWeight: 400,
										lineHeight: "17.5px",
										letterSpacing: "-1%",
										color: "#7A7985",
										mb: "0.8rem",
									}}
								>
									Invite friends to collaborate!
								</Typography>
							</Grid> */}

							{/* <Button
								type="submit"
								sx={{
									background:
										isDisabled || user.userAccountStatus === "SUSPEND"
											? "#888888"
											: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
									height: "40px",
									color: "#FFFFFF",
									fontSize: "1rem !important",
									fontWeight: "500",
									lineHeight: "1rem !important",
									fontFamily: "Lexend",
									borderRadius: "24px",
								}}
								size="large"
								disabled={isDisabled || user.userAccountStatus === "SUSPEND"}
								endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								fullWidth
								variant="contained"
								onClick={() => handleInviteBtnClick()}
							>
								Invite Friends ({countText})
							</Button> */}
						</Grid>
					</Grid>
					<Grid item xs={12} container sx={{ mt: 4 }}>
						<Grid item lg={2.9} className="extendColHome leftPanelWidth">
							<HomeSideBar userDetails={usersData} />
						</Grid>

						<Grid
							container
							item
							lg={6.04}
							sx={{ pl: 0.5 }}
							rowGap={1}
							className="extendColTab centerPanelWidth"
						>
							{/* {activeDevice === null ? (
								<Grid item>
									<Card
										sx={{
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
											mb: 3,
										}}
									>
										<CardContent>
											<Grid container={true} spacing={{ xs: 2 }}>
												<Grid
													item={true}
													className="center-align"
													sx={{ m: "auto" }}
												>
													<CardMedia
														component="img"
														image={LandingPage}
														alt="landing-page"
													/>
												</Grid>
												<Grid item={true} sx={{ textAlignLast: "center" }}>
													<Grid>
														<Typography
															// className="typography-field-custom-font-size left-align"
															sx={{
																fontWeight: "500px !important",
																fontSize: "1.5rem !important",
															}}
														>
															{i18n.home.accountSuccessfulMsg}
														</Typography>
													</Grid>
													<Grid>
														<Typography
															// className="typography-field-custom-font-size left-align"
															sx={{
																fontWeight: "500px !important",
																fontSize: "1.5rem !important",
															}}
														>
															{i18n.home.landingPageInfo}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
										<CardActions className="right-align">
											<Grid
											// spacing={2}
											>
												<Button
													className="submit-button"
													fullWidth
													size="large"
													endIcon={
														<img
															src={RightAarrow}
															alt="Right Arrow"
															style={{ height: "24px", width: "24px" }}
														/>
													}
													variant="contained"
													onClick={() => setOpen(true)}
													sx={{
														height: "48px",
														borderRadius: "24px",
														mr: 1,
														mb: 1,
													}}
												>
													<Typography>{i18n.jamSession.add}</Typography>
												</Button>
											</Grid>
										</CardActions>
									</Card>
								</Grid>
							) : (
								""
							)} */}

							<Grid item className=" postHome" xs={12}>
								<PostsHome />
							</Grid>

							{/*<Grid item>
								<AdsComponent dataAdSlot="5657049229" />
							</Grid>*/}
						</Grid>

						<Grid
							item
							container
							direction={"column"}
							lg={2.9}
							sx={{ alignItems: "end" }}
							rowGap={2}
							className="rightPanelWidth"
						>
							{/* Feedback Button */}
							<Grid item sx={{ width: "288px" }} className="scheduleBtn">
								<Card
									sx={{
										mb: 3,
										minWidth: 288,
										width: 288,
										maxWidth: "288px",
										height: "fit-content",
										boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
										borderRadius: "12px",
									}}
								>
									<Button
										size="large"
										sx={{
											width: "100%",
											justifyContent: "center",
										}}
										variant="contained"
										onClick={preorderStream}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									>
										<Typography
											sx={{
												color: "#ffffff",
												fontFamily: "Lexend",
												fontSize: "16px",
											}}
										>
											Order Lutefish Stream now
										</Typography>
									</Button>
								</Card>
								<Card
									sx={{
										mb: 3,
										minWidth: 288,
										width: 288,
										maxWidth: "288px",
										height: "fit-content",
										boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
										borderRadius: "12px",
									}}
								>
									<Button
										className="submit-button button-field-custom-font-size"
										size="large"
										sx={{
											width: "100%",
											fontSize: "18px !important",
											justifyContent: "center",
										}}
										variant="contained"
										onClick={() => setFeedbackOpen(true)}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									>
										<Typography>{i18n.home.sendFeedback}</Typography>
									</Button>
								</Card>
							</Grid>
							{/* <LeftPanel activeDevice={activeDevice} /> */}
							{
								// user?.isCollaborationActive === true &&
								<Grid item sx={{ width: "288px" }}>
									<Sessions
										refresh={true}
										source="home"
										reqStartdate={new Date()}
										filter={"agenda"}
										setAlertOnEditSession={setAlertOnEditSession}
									/>
								</Grid>
							}
							<MyActivitiesSidePanel />
							{/* <Grid item sx={{ width: '288px' }}>
								<Sessions
									refresh={true}
									source="home"
									reqStartdate={new Date()}
									filter={"agenda"}
									setAlertOnEditSession={setAlertOnEditSession}
								/>
							</Grid>
							<Grid item sx={{ width: '288px' }}>
								<HelpAndTips
									title={i18n.home.instructions.appFeatureUpdatesTitle}
									text={i18n.home.instructions.appFeatureUpdatesDesc}
								/>
							</Grid>
							<Grid item sx={{ width: '288px' }}>
								<HelpAndTips
									title={i18n.home.instructions.appFunctionalityTitle}
									text={i18n.home.instructions.appFunctionalityDesc}
								/>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			</div>
			{/*
			<AddDeviceDialog
				open={open}
				onSuccess={handleSuccess}
				onClose={() => setOpen(false)}
			/>
			{openScheduleSession && (
				<ScheduleSession
					open={openScheduleSession}
					handleClose={handleEditSessionClose}
				></ScheduleSession>
			)} */}

			{inviteFriendDialogOpen && (
				<Dialog
					sx={{
						border: "1px solid #888888",
						borderRadius: "15px",
						boxShadow: "0px 1px 16px 1px #D9E7FFC4",
						margin: "auto",
						padding: "6rem !important",
					}}
					fullWidth
					open={inviteFriendDialogOpen}
				>
					<img
						src={closeIcon}
						alt="close"
						style={{
							position: "absolute",
							top: "0.5rem",
							right: "0.5rem",
							cursor: "pointer",
						}}
						onClick={() => handleDailogClose()}
					/>
					<Grid sx={{ padding: "0.5rem 1rem" }}>
						<DialogTitle className="edit-heading-name">
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								<Typography
									sx={{
										fontWeight: 500,
										color: "#000",
										fontSize: "24px !important",
										lineHeight: "30px !important",
									}}
								>
									Send Invitation
								</Typography>
							</Box>
						</DialogTitle>

						{/* Placeholder content */}
						<DialogContent sx={{ margin: "auto", width: "480px !important" }}>
							<Typography
								sx={{
									fontWeight: 400,
									fontSize: "1rem",
									lineHeight: "1.25rem",
									color: "#7A7985",
									display: "flex",
									alignItems: "center",
									mb: "1.5rem",
								}}
								align="center"
							>
								You can invite your friends to join! We will email them an
								invitation code!
							</Typography>

							<Grid
								sx={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
									bgcolor: "#F8F8F8",
									mb: "20px",
								}}
							>
								<Typography
									sx={{
										fontWeight: 500,
										fontSize: "1rem",
										lineHeight: "1.25rem",
										color: "#7A7985",
										display: "flex",
										alignItems: "center",
										padding: "0.5rem",
									}}
									align="center"
								>
									Available referrals: {newEmails.length}
								</Typography>
								<Typography
									sx={{
										fontWeight: 500,
										fontSize: "1rem",
										lineHeight: "1.25rem",
										color: "#7A7985",
										display: "flex",
										alignItems: "center",
										padding: "0.5rem",
										mr: "1.5rem",
									}}
									align="center"
								>
									Used: {existingEmails.length}
								</Typography>
							</Grid>
							{existingEmails.map((email, index) => {
								return (
									<Grid sx={{ height: "92px" }}>
										<Typography
											sx={{
												fontWeight: "400",
												fontSize: "16px",
												lineHeight: "20px",
												fontFamily: "Lexend",
												mb: 1,
												color: "#7A7985",
											}}
										>
											Email {index + 1}
										</Typography>
										<TextField
											type="email"
											fullWidth
											inputProps={{
												style: {
													borderColor: "customColor.gray1",
													background: "#F8F8F8",
													color: "customColor.black",
													height: 23,
												},
											}}
											value={email}
											disabled={true}
										/>
									</Grid>
								);
							})}
							{newEmails.map((item, index) => {
								return (
									<Grid sx={{ height: "92px" }}>
										<Typography
											sx={{
												fontWeight: "400",
												fontSize: "16px",
												lineHeight: "20px",
												fontFamily: "Lexend",
												mb: 1,
												color: "#7A7985",
											}}
										>
											Email {existingEmails.length + index + 1}
										</Typography>
										<FormControl fullWidth>
											<TextField
												type="email"
												fullWidth
												inputProps={{
													style: {
														borderColor: "customColor.gray1",
														background: "#F8F8F8",
														color: "customColor.black",
														height: 23,
													},
												}}
												value={item.email}
												onChange={(e) =>
													handleNewEmailChange(index, e.target.value)
												}
												disabled={item.sent}
											/>
											{item.err && (
												<FormHelperText
													sx={{
														color: "red",
														marginLeft: "14px",
														display: "flex",
														justifyContent: "flex-end",
													}}
												>
													{item.err}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
								);
							})}
						</DialogContent>

						<DialogActions>
							<Grid
								container
								columnSpacing={2}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
								}}
								mt={-1.5}
							>
								<Grid item={true}>
									<Button
										sx={{ width: "140px", height: "40px" }}
										className="cancel-button"
										size="large"
										variant="outlined"
										onClick={() => {
											handleDailogClose();
										}}
									>
										Cancel
									</Button>
								</Grid>

								<Grid item={true}>
									<Button
										sx={{ width: "217px", height: "40px", mr: "1rem" }}
										className="submit-button"
										size="large"
										variant="contained"
										onClick={() => {
											setDialogSubmitBtn(true);
											handleSendInvitationClick();
										}}
										disabled={remainingCount === 0 || dialogSubmitBtn}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									>
										<Typography
											sx={{
												fontSize: 500,
												fontsize: "20px",
												lineHeight: "22.5px",
												letterSpacing: "5%",
											}}
										>
											{dialogSubmitBtn ? "sending..." : "Send Invitation"}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Grid>
				</Dialog>
			)}

			{/* Feedback Dialog */}

			{feedbackOpen && (
				<CreateTicket
					handleDialogClose={handleDialogClose}
					createTicketDialog={feedbackOpen}
					setCreateTicketDialog={setFeedbackOpen}
					setShowAlert={setShowAlert}
					setAlertMsg={setAlertMsg}
					setAlertStatus={setAlertStatus}
					messageType={"Feedback"}
					type={"feedback"}
				/>
			)}
		</>
	);
};

export default Home;
