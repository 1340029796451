import {
	Box,
	Button,
	Card,
	CardMedia,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormHelperText,
	Grid,
	IconButton,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import Group from "../../images/Group.svg";
import RightArrow from "../../images/right arrow.svg";
import { useEffect, useRef, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Like from "../../images/like.svg";
import calendarIcon from "../../images/calendar.svg";
import Location from "../../images/location.svg";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../images/edit-white.svg";
import DeleteIcon from "../../images/trash-orange.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useUser } from "../../context/UserContext";
import NewGig from "./NewGig";
import { Stack } from "@mui/system";
import CloseIcon from "../../images/close.svg";
import { EventsSidePanel } from "../commonComponents/EventsSidePanel";
import MicrophoneG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Xylophone_Marimba.svg";
import SaxophoneG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Sousaphone.svg";
import AccordionG from "../../icons/Color/SVG/Color_Accordian.svg";
import GuitarG from "../../icons/Color/SVG/Color_Acoustic Guitar.svg";
import Alto_SaxophoneG from "../../icons/Color/SVG/Color_Alto Sax.svg";
import BagpipesG from "../../icons/Color/SVG/Color_Bagpipes.svg";
import BanjoG from "../../icons/Color/SVG/Color_Banjo.svg";
import BaritoneEG from "../../icons/Color/SVG/Color_Baritone_Euphonium.svg";
import BaritoneSG from "../../icons/Color/SVG/Color_Baritone Sax.svg";
import BClarinetG from "../../icons/Color/SVG/Color_Bass Clarinet.svg";
import BassGuitarG from "../../icons/Color/SVG/Color_Bass Guitar.svg";
import BassoonG from "../../icons/Color/SVG/Color_Bassoon.svg";
import BellsG from "../../icons/Color/SVG/Color_Bells.svg";
import CelloG from "../../icons/Color/SVG/Color_Cello.svg";
import ClarinetG from "../../icons/Color/SVG/Color_Clarinet.svg";
import ContrabassoonG from "../../icons/Color/SVG/Color_Contrabassoon.svg";
import CornetG from "../../icons/Color/SVG/Color_Cornet.svg";
import DrumsG from "../../icons/Color/SVG/Color_Drums.svg";
import DulcimerG from "../../icons/Color/SVG/Color_Dulcimer.svg";
import EGuitarG from "../../icons/Color/SVG/Color_Electric Guitar.svg";
import English_HornG from "../../icons/Color/SVG/Color_English Horn.svg";
import FlugelhornG from "../../icons/Color/SVG/Color_Flugelhorn.svg";
import FluteG from "../../icons/Color/SVG/Color_Flute.svg";
import French_HornG from "../../icons/Color/SVG/Color_French Horn.svg";
import HarmonicaG from "../../icons/Color/SVG/Color_Harmonica.svg";
import HarpG from "../../icons/Color/SVG/Color_Harp.svg";
import KeyboardG from "../../icons/Color/SVG/Color_Keyboard.svg";
import MandolinG from "../../icons/Color/SVG/Color_Mandolin.svg";
import OboeG from "../../icons/Color/SVG/Color_Oboe.svg";
import OrganG from "../../icons/Color/SVG/Color_Organ.svg";
import PercussionG from "../../icons/Color/SVG/Color_Percussion.svg";
import PianoG from "../../icons/Color/SVG/Color_Piano.svg";
import PiccoloG from "../../icons/Color/SVG/Color_Piccolo.svg";
import Piccolo_TrumpetG from "../../icons/Color/SVG/Color_Piccolo Trumpet.svg";
import RecorderG from "../../icons/Color/SVG/Color_Recorder.svg";
import SitarG from "../../icons/Color/SVG/Color_Sitar.svg";
import Soprano_SaxophoneG from "../../icons/Color/SVG/Color_Soprano Sax.svg";
import SousaphoneG from "../../icons/Color/SVG/Color_Sousaphone.svg";
import SynthesizerG from "../../icons/Color/SVG/Color_Synthesizer.svg";
import Tenor_SaxophoneG from "../../icons/Color/SVG/Color_Tenor Sax.svg";
import TromboneG from "../../icons/Color/SVG/Color_Trombone.svg";
import TrumpetG from "../../icons/Color/SVG/Color_Trumpet.svg";
import TubaG from "../../icons/Color/SVG/Color_Tuba.svg";
import UkuleleG from "../../icons/Color/SVG/Color_Ukulele.svg";
import Upright_BassG from "../../icons/Color/SVG/Color_Upright Bass.svg";
import ViolaG from "../../icons/Color/SVG/Color_Viola.svg";
import ViolinG from "../../icons/Color/SVG/Color_Violin.svg";
import XylophoneG from "../../icons/Color/SVG/Color_Xylophone_Marimba.svg";
import VoiceG from "../../icons/Color/SVG/Color_Voice.svg";
import DefaultIconG from "../../images/Instrument placeholder.svg";

//Genre icons
import Americana from "../../icons/Genres/Color/Color_Americana.svg";
import AvantGarde from "../../icons/Genres/Color/Color_Avante-Garde.svg";
import Baroque from "../../icons/Genres/Color/Color_Baroque.svg";
import BigBand from "../../icons/Genres/Color/Color_Big Band.svg";
import Bluegrass from "../../icons/Genres/Color/Color_Bluegrass.svg";
import Blues from "../../icons/Genres/Color/Color_Blues.svg";
import Bollywood from "../../icons/Genres/Color/Color_Bollywood.svg";
import BroadwayShowtunes from "../../icons/Genres/Color/Color_Broadway-Showtunes.svg";
import ChamberMusic from "../../icons/Genres/Color/Color_Chamber Music.svg";
import ClassicRock from "../../icons/Genres/Color/Color_Classic Rock.svg";
import Classical from "../../icons/Genres/Color/Color_Classical.svg";
import ContemporaryClassical from "../../icons/Genres/Color/Color_Contemporary Classical.svg";
import Country from "../../icons/Genres/Color/Color_Country.svg";
import Disco from "../../icons/Genres/Color/Color_Disco.svg";
import Drumline from "../../icons/Genres/Color/Color_Drumline.svg";
import EDM from "../../icons/Genres/Color/Color_EDM.svg";
import ElectronicDance from "../../icons/Genres/Color/Color_Electronic-Dance.svg";
import Folk from "../../images/genres/folk.svg"; 
import Funk from "../../icons/Genres/Color/Color_Funk.svg";
import Genere from "../../images/genres/genreC.svg";
import Gospel from "../../icons/Genres/Color/Color_Gospel.svg";
import HiphopRap from "../../icons/Genres/Color/Color_HipHop-Rap.svg";
import House from "../../icons/Genres/Color/Color_House.svg";
import IndieAlternative from "../../icons/Genres/Color/Color_Indie-Alt.svg";
import Jazz from "../../icons/Genres/Color/Color_Jazz.svg";
import KPop from "../../icons/Genres/Color/Color_KPop.svg";
import Latin from "../../icons/Genres/Color/Color_Latin.svg";
import Metal from "../../icons/Genres/Color/Color_Metal.svg";
import Minimalism from "../../icons/Genres/Color/Color_Minimalism.svg";
import NewAge from "../../icons/Genres/Color/Color_New Age.svg";
import Opera from "../../icons/Genres/Color/Color_Opera.svg";
import Orchestral from "../../icons/Genres/Color/Color_Orchestral.svg";
import Pop from "../../icons/Genres/Color/Color_Pop.svg";
import Punk from "../../icons/Genres/Color/Color_Punk.svg";
import RB from "../../icons/Genres/Color/Color_R&B.svg";
import Ragtime from "../../icons/Genres/Color/Color_Ragtime.svg";
import Reggae from "../../icons/Genres/Color/Color_Reggae.svg";
import Reggaeton from "../../icons/Genres/Color/Color_Reggaeton.svg";
import Religious from "../../icons/Genres/Color/Color_Religious.svg";
import Rock from "../../icons/Genres/Color/Color_Rock.svg";
import Romantic from "../../icons/Genres/Color/Color_Romantic.svg";
import Salsa from "../../icons/Genres/Color/Color_Salsa.svg";
import Ska from "../../icons/Genres/Color/Color_Ska-18.svg";
import Soul from "../../icons/Genres/Color/Color_Soul.svg";
import Soundtrack from "../../icons/Genres/Color/Color_Soundtrack.svg";
import Swing from "../../icons/Genres/Color/Color_Swing.svg";
import Symphonic from "../../icons/Genres/Color/Color_Symphonic.svg";
import Techno from "../../icons/Genres/Color/Color_Techno.svg";
import WindEnsemble from "../../icons/Genres/Color/Color_Wind Ensemble.svg";

import { Client } from "@twilio/conversations";
import {
	addConversation,
	checkUserByUniqueName,
	getToken,
} from "../Messages/api";
import { bindActionCreators } from "redux";
import { AppState, actionCreators } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getSdkConversationObject } from "../Messages/conversations-objects";
import {
	updateConversation,
	upsertConversation,
} from "../../store/action-creators";
import { SetParticipantsType, SetSidType } from "../../types";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { useAlertStatus } from "../../context/AlertStatusContext";
import ShareIcon from "../../images/share1.svg";
import CreatePostNew from "../Posts/CreatePostNew";
import { format } from "date-fns";
import Picture from "../../images/Posts/picture-upload-blue.svg";
import Music from "../../images/Posts/music-upload-blue.svg";
import CancelIcon from "../../images/Group 4282.svg";
import ImageCropper from "../../pages/ImageCropper";

const dummyGigDetail = {
	id: 2,
	type: "GIG",
	title: "Gig Title",
	description:
		"Live event by Taylor Swift. Playing her latest albums along with her band. Live event by Taylor Swift. Playing her latest albums along with her bandLive event by Taylor Swift. Playing her latest albums along with her bandLive event by Taylor Swift. Playing her latest albums along with her band",
	cost: "10$",
	cityCountry: "Mumbai, Maharashtra, India",
	startDateTime: "2023-09-20 07:30:14",
	endDateTime: "2023-09-20 08:30:14",
	timezone: "Asia/Calcutta",
	duration: "30",
	requirements:
		"Need Guitarist & drummers for our band. We have equipments. Need Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipmentsNeed Guitarist & drummers for our band. We have equipments",
	instruments: ["Guitar", "Drums"],
	thumbnailUrl: "/nwusrb7/2f68512db5f34b72",
	bannerUrl: "",
	eventTicketLink: "",
	userProfile: {
		user: {
			id: 157,
		},
	},
	masterInstruments: {
		id: 8,
		instrumentName: "",
	},
	canApplyForGig: true,
};

type RouteParams = {
	gigId: string;
};

const ViewGig = (props: any) => {
	const user = useUser();
	const navigate = useNavigate();
	const { updateAlertStatus } = useAlertStatus();
	const param = useParams<RouteParams>();
	const token = localStorage.getItem("sessionId");
	const [gigDetail, setGigDetails] = useState<any>(dummyGigDetail);
	const [likeCount, setLikeCount] = useState<any>();
	const [liked, setLiked] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [openEditGigPopup, setOpenEditGigPopup] = useState(false);
	const [openSendMessage, setOpenSendMessage] = useState(false);
	const [instrument, setInstrument] = useState<any[]>();
	const [openDeleteGigPopup, setOpenDeleteGigPopup] = useState(false);
	const [loading, setLoading] = useState(true);
	const [gigMessage, setGigMessage] = useState<any>("");
	const dispatch = useDispatch();
	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);
	const conversations = useSelector((state: AppState) => state.convos);
	const [genre, setGenres] = useState<any>([]);

	const [sharePost, setSharePost] = useState(false);

	const inputFileRef = useRef<any>(null);
	const [sending, setSending] = useState(false);
	const [file, setFile] = useState<any>("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	const [result, setResult] = useState<any>("");
	const [music, setMusic] = useState<any>("");
	const [open, setOpen] = useState(false);
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
	);

	const genreIcons: { [key: string]: any } = {
		Americana: Americana,
		"Avant-garde": AvantGarde,
		Baroque: Baroque,
		"Big Band": BigBand,
		Bluegrass: Bluegrass,
		Blues: Blues,
		Bollywood: Bollywood,
		"Broadway/Showtunes": BroadwayShowtunes,
		"Chamber-Music": ChamberMusic,
		"Classic Rock": ClassicRock,
		Classical: Classical,
		"Contemporary Classical": ContemporaryClassical,
		Country: Country,
		Disco: Disco,
		Drumline: Drumline,
		EDM: EDM,
		"Electronic/Dance": ElectronicDance,
		Folk: Folk,
		Genre:Genere,
		Funk: Funk,
		Gospel: Gospel,
		"Hip-hop/Rap": HiphopRap,
		House: House,
		"Indie/Alternative": IndieAlternative,
		Jazz: Jazz,
		"K-Pop": KPop,
		Latin: Latin,
		Metal: Metal,
		Minimalism: Minimalism,
		"New Age": NewAge,
		Opera: Opera,
		Orchestral: Orchestral,
		Pop: Pop,
		Punk: Punk,
		"R&B": RB,
		Ragtime: Ragtime,
		Reggae: Reggae,
		Reggaeton: Reggaeton,
		Religious: Religious,
		Rock: Rock,
		Romantic: Romantic,
		Salsa: Salsa,
		Ska: Ska,
		Soul: Soul,
		Soundtrack: Soundtrack,
		Swing: Swing,
		Symphonic: Symphonic,
		Techno: Techno,
		"Wind Ensemble": WindEnsemble,
	};

	const instrumentIcons: { [key: string]: any } = {
		Accordion: AccordionG,
		Banjo: BanjoG,
		"Bass Guitar": BassGuitarG,
		Drums: DrumsG,
		Flute: FluteG,
		Guitar: GuitarG,
		Keyboard: KeyboardG,
		Microphone: MicrophoneG,
		Bagpipes: BagpipesG,
		Piano: PianoG,
		Saxophone: SaxophoneG,
		Trumpet: TrumpetG,
		Violin: ViolinG,
		"Acoustic Guitar": GuitarG,
		"Alto Saxophone": Alto_SaxophoneG,
		"Baritone/Euphonium": BaritoneEG,
		"Baritone Saxophone": BaritoneSG,
		"Bass Clarinet": BClarinetG,
		Bassoon: BassoonG,
		Bells: BellsG,
		Cello: CelloG,
		Clarinet: ClarinetG,
		Contrabassoon: ContrabassoonG,
		Cornet: CornetG,
		Dulcimer: DulcimerG,
		"Electric Guitar": EGuitarG,
		"English Horn": English_HornG,
		Flugelhorn: FlugelhornG,
		"French Horn": French_HornG,
		Harmonica: HarmonicaG,
		Harp: HarpG,
		Mandolin: MandolinG,
		Oboe: OboeG,
		Organ: OrganG,
		Percussion: PercussionG,
		Piccolo: PiccoloG,
		"Piccolo Trumpet": Piccolo_TrumpetG,
		Recorder: RecorderG,
		Sitar: SitarG,
		"Soprano Saxophone": Soprano_SaxophoneG,
		Sousaphone: SousaphoneG,
		Synthesizer: SynthesizerG,
		"Tenor Saxophone": Tenor_SaxophoneG,
		Trombone: TromboneG,
		Tuba: TubaG,
		Ukulele: UkuleleG,
		"Upright Bass": Upright_BassG,
		Viola: ViolaG,
		"Xylophone/Marimba": XylophoneG,
		"Voice Alto": VoiceG,
		"Voice Baritone": VoiceG,
		"Voice Bass": VoiceG,
		"Voice Mezzo Soprano": VoiceG,
		"Voice Soprano": VoiceG,
		"Voice Tenor": VoiceG,
	};

	const getIcons = (instrument: string) => {
		const iconSrc = instrumentIcons[instrument];
		return iconSrc ? (
			<img
				src={iconSrc}
				style={{ height: "24px", width: "24px" }}
				alt={instrument}
			/>
		) : (
			<img
				src={DefaultIconG}
				style={{ height: "24px", width: "24px" }}
				alt="DefaultIcon"
			/>
		);
	};

	const getGenre = (genre: string) => {
		const iconSrc = genreIcons[genre];
		return iconSrc ? (
			<img
				src={iconSrc}
				style={{ height: "24px", width: "24px" }}
				alt={genre}
			/>
		) : (
			<img
				src={DefaultIconG}
				style={{ height: "24px", width: "24px" }}
				alt="DefaultIcon"
			/>
		);
	};

	useEffect(() => {
		fetchGigData();
	}, []);

	const fetchGigData = () => {
		setLoading(true);
		//   setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-event/details`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: param.gigId,
			}),
		})
			.then((res) => {
				if (res.status !== 200) {
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if(data?.deleted === false){

					setLikeCount(data.likesCount);
					setGigDetails(data);
					setLiked(data.userReaction);
					setGenres(data.genres ? data.genres.split(",") : []);
					const instrumentArr = data.masterInstruments.map(
						(instrument: any) => instrument.instrumentName
					);
					setInstrument(instrumentArr);
					// const
					// setInstrument(instrumentsString);
					setLoading(false);
				}else{
					setTimeout(()=>{
						updateAlertStatus(true, false, "The gig doesn't exist anymore.");
						navigate(-1);
					}, 2000);
				}
			})
			.catch((err) => {
				//   setLoading(false);
				setLoading(false);
			});
	};

	const handleLike = (status: any) => {
		let shouldResetTimeout = true;

		const timeoutId = setTimeout(() => {
			if (shouldResetTimeout) {
				setLikeCount(gigDetail.likesCount);
				setLiked(gigDetail.userReaction);
			}
		}, 2000);

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events-reaction/react`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				events: {
					id: param.gigId,
				},
			}),
		})
			.then((res) => {
				clearTimeout(timeoutId);
				shouldResetTimeout = false;

				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setLikeCount(result.likesCount);
				setLiked(result.isReacted);
			})
			.catch((err) => {
				clearTimeout(timeoutId);
				shouldResetTimeout = false;

				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const handleSuccess = () => {
		fetchGigData();
		setOpenEditGigPopup(false);
		props.onSuccess();
	};
	const handleOnClose = () => {
		setOpenEditGigPopup(false);
	};

	const handleDelete = () => {
		// const eventsDTO = { id: param.gigId };

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events/delete-event`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(gigDetail),
		})
			.then((response) => {
				if (response.status === 502) {
					navigate("/defaultPage");
				} else if (response.status >= 400) {
					ErrorAlert(
						response.status,
						setAlertStatus,
						setShowAlert,
						setAlertMsg
					);
				} else {
					navigate("/gigs");
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				//setAlertMsg("Network Error! Please try again..");
			});
	};

	const utcTime: string = gigDetail.startDateTime;
	const date = new Date(utcTime);
	const finalFormattedDate = format(date, "MMM dd, yyyy - hh:mm a");

	const handleCloseDeletePopup = () => {
		setOpenDeleteGigPopup(false);
	};

	async function updateCurrentConvo(
		setSid: SetSidType,
		convo: ReduxConversation,
		updateParticipants: SetParticipantsType
	) {
		setSid(convo.sid);

		const participants = await getSdkConversationObject(
			convo
		).getParticipants();
		updateParticipants(participants, convo.sid);
	}

	const sendGigMessage = async () => {
		var token = localStorage.getItem("conversationToken");
		if (token === "") {
			token = await getToken(user.email, "");
			localStorage.setItem("conversationToken", token);
		}
		const client = new Client(token as string);
		let title = gigDetail.firstName + " " + gigDetail.lastName;
		let uniqueName = "GIG@@@" + [gigDetail.email].sort().join("-");
		let uniqueNameForCheck =
			"GIG@@@" + [gigDetail.email, user.email].sort().join("-");

		// Before you use the client, subscribe to the `'initialized'` event.
		try {
			client.on("initialized", async () => {
				// const checkConversationExists = await client.getConversationByUniqueName(uniqueName);
				client
					.getConversationByUniqueName(uniqueNameForCheck)
					.then((conversationDetails) => {
						conversationDetails
							.sendMessage(gigMessage)
							.then(async (convo) => {
								updateAlertStatus(true, true, "Applied for Gig Successfully");
								setGigMessage("");
								setOpenSendMessage(false);
							})
							.catch((error: any) => {
								console.error("Error sending message:", error);
								updateAlertStatus(true, false, "Something went wrong");
							});
					})
					.catch(async (error: any) => {
						if (error.message == "Not Found") {
							const convo = await addConversation(
								title,
								uniqueName,
								updateParticipants,
								client,
								addNotifications
							);

							if (convo.sid) {
								const conversationDetails = await client.getConversationBySid(
									convo.sid
								);
								conversationDetails
									.sendMessage(gigMessage)
									.then(() => {
										// updateCurrentConversation(convo.sid);
										// navigate('/messages');
										updateAlertStatus(
											true,
											true,
											"Applied for Gig Successfully"
										);
										setGigMessage("");
										setOpenSendMessage(false);
									})
									.catch((error: any) => {
										console.error("Error sending message:", error);
									});
							}
						} else {
							updateAlertStatus(true, false, "Something went wrong");
						}
					});
			});
		} catch (error) {}
	};

	const handleShare = () => {
		setSharePost(true);
	};
	const handlePostClose = (call: boolean) => {
		if (call) {
			navigate("/home");
		}
		setSharePost(false);
	};
	const handleGigClose = () => {
		setGigMessage("");
		setFile("");
		setResult("");
		setMusic("");
		setOpenSendMessage(false);
	};

	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.type.startsWith("image/")) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			setOpen(true);
		} else {
			updateAlertStatus(true, false, "Please select valid picture");
		}
		if (inputFileRef.current) {
			inputFileRef.current.value = null; // Clear the input value
			const newInput = document.createElement("input");
			newInput.style.display = "none";
			newInput.type = "file";
			newInput.accept = "image/*";
			newInput.addEventListener("change", handleOpenEditor);
			inputFileRef.current.parentNode.replaceChild(
				newInput,
				inputFileRef.current
			);
			inputFileRef.current = newInput;
		}
	};

	const handleOpenMusic = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		//Convert bytes to MB & Round to the nearest integer
		var roundedFileSizeInMB = Math.round(file.size / (1024 * 1024));
		// Convert MB back to bytes
		const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);

		if (file.type.startsWith("audio/")) {
			// if (roundedFileSizeInBytes > 10 * 1024 * 1024) {
			// 	updateAlertStatus(
			// 		true,
			// 		false,
			// 		"Music size exceeds the maximum allowed limit of 10Mb. Please try again."
			// 	);
			// } else {
				setMusic(URL.createObjectURL(file));
				setFile(file);
			//}
			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.type = "file";
				newInput.style.display = "none";
				newInput.accept = "audio/*,.mp3,.wav,.mpeg";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const handlePictureChange = () => {
		setImagePreviewUrl("");
		setResult("");
		setFile("");
	};
	const handleMusicChange = () => {
		setMusic("");
		setFile("");
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendTextMessage = async (conversationDetails: any) => {
		conversationDetails
			.sendMessage(gigMessage)
			.then(async (convo: any) => {
				// props.menuClose();
				updateAlertStatus(true, true, "Applied for Gig Successfully");
				setGigMessage("");
				setFile("");
				setResult("");
				setMusic("");
				setSending(false);
				setOpenSendMessage(false);
			})
			.catch((error: any) => {
				console.error("Error sending message:", error);
				updateAlertStatus(true, false, "Something went wrong");
			});
	};

	const sendFileMessage = async (conversationDetails: any) => {
		const formData = new FormData();
		formData.append("file", file);
		conversationDetails
			.sendMessage(gigMessage)
			.then(async (convo: any) => {
				return conversationDetails.sendMessage(formData);
			})
			.then(async (convo: any) => {
				// props.menuClose();
				updateAlertStatus(true, true, "Applied for Gig Successfully");
				setGigMessage("");
				setSending(false);
				setFile("");
				setResult("");
				setMusic("");
				setOpenSendMessage(false);
			})
			.catch((error: any) => {
				console.error("Error sending message:", error);
				updateAlertStatus(true, false, "Something went wrong");
			});
	};

	const sendMessageText = async () => {
		// setIsLoading(true);

		setSending(true);
		var token = localStorage.getItem("conversationToken");
		if (token === null || token === "") {
			token = await getToken(user.email, "");
			localStorage.setItem("conversationToken", token);
		}
		const client = new Client(token as string);
		let title = gigDetail.firstName + " " + gigDetail.lastName;
		let uniqueName = [gigDetail.email].sort().join("-");
		let uniqueNameForCheck =
			[gigDetail.email, user.email].sort().join("-");

		try {
			client.on("initialized", async () => {
				client
					.getConversationByUniqueName(uniqueNameForCheck)
					.then((conversationDetails) => {
						if (file) {
							sendFileMessage(conversationDetails);
						} else {
							sendTextMessage(conversationDetails);
						}
						// conversationDetails
						// 	.sendMessage(file ? formData : messageText)
						// 	.then(async (convo) => {
						// 		props.menuClose();
						// 		updateAlertStatus(true, true, "Message sent successfully");
						// 		setGigMessage("");
						// 		setSending(false);
						// 		setOpenSendMessage(false);
						// 		closeShareProfileDialog(false);
						// 		setOpenSendMessage(false);
						// 	})
						// 	.catch((error: any) => {
						// 		console.error("Error sending message:", error);
						// 		updateAlertStatus(true, false, "Something went wrong");
						// 	});
					})
					.catch(async (error: any) => {
						if (error.message === "Not Found") {
							const convo = await addConversation(
								title,
								uniqueName,
								updateParticipants,
								client,
								addNotifications
							);

							if (convo.sid) {
								const conversationDetails = await client.getConversationBySid(
									convo.sid
								);
								if (file) {
									sendFileMessage(conversationDetails);
								} else {
									sendTextMessage(conversationDetails);
								}
								// conversationDetails
								// 	.sendMessage(file ? formData : messageText)
								// 	.then(() => {
								// 		// updateCurrentConversation(convo.sid);
								// 		// navigate('/messages');
								// 		updateAlertStatus(
								// 			true,
								// 			true,
								// 			"Message sent successfully"
								// 		);
								// 		setGigMessage("");
								// 		setSending(false);
								// 		setOpenSendMessage(false);
								// 		closeShareProfileDialog(false);
								// 	})
								// 	.catch((error: any) => {
								// 		// setSending(false);
								// 		console.error("Error sending message:", error);
								// 	});
							}
						} else {
							updateAlertStatus(
								true,
								false,
								"Something went wrong, Please try again"
							);
							// setIsLoading(false);
							setSending(true);
						}
					});
			});
		} catch (error) {
			// setSending(false);
		}
	};

	useEffect(() => {
		if (file && file.type.startsWith("image/")) {
			const objectUrl = URL.createObjectURL(file);
			setResult(objectUrl);

			// Cleanup function to revoke the object URL when the component unmounts or when file changes
			return () => {
				URL.revokeObjectURL(objectUrl);
			};
		}
	}, [file]);
	return (
		<>
			<Grid className="mainPanel" sx={{ margin: "auto" }}>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				{loading ? (
					<Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
						<CircularProgress /> {/* The loading spinner */}
						<Grid container item justifyContent="center">
							Searching ...
						</Grid>
					</Grid>
				) : (
					<Grid
						container={true}
						className="column mainPanel"
						// rowSpacing={{ xs: 1 }}
					>
						<Grid
							item={true}
							sx={{ zIndex: 99, width: "100%", backgroundColor: "white" }}
						>
							<Grid item={true} xs={12} mt={3}>
								<Grid container={true} columns={{ xs: 12 }}>
									<Grid item xs={6} display={"flex"} flexDirection={"row"}>
										<Button
											sx={{ alignSelf: "flex-start" }}
											variant="text"
											startIcon={
												<img src={Group} alt="Back to previous page" />
											}
											onClick={() => {
												window.history.back();
											}}
										>
											
										</Button>
											<Typography
												sx={{
													fontStyle: "normal",
													fontSize: "36px !important",
													lineHeight: "normal",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													maxWidth: "50rem",
													mt: "-5px"
												}}
											>
												{gigDetail.title}
												{/* (id={param.eventId}) */}
											</Typography>
									</Grid>
									<Grid item xs={6} style={{ textAlign: "end" }}>
										{user?.userProfileId === gigDetail.userProfile?.id &&
											user.userAccountStatus !== "SUSPEND" && (
												<>
													<Button
														sx={{
															borderRadius: "24px",
															border: "2px solid #FF702A",
															backgroundColor: "#FFFFFF",
															color: "#FF702A",
															marginRight: "8px",
															width: "139px",
															height: " 40px",
															padding: "8px, 21.5px, 8px, 21.5px",
															justifyContent: "center",
															fontFamily: "Lexend",
															fontSize: "1.125rem",
															fontStyle: "normal",
															fontWeight: 500,
														}}
														size="large"
														startIcon={<img src={DeleteIcon} alt="Export" />}
														variant="outlined"
														onClick={() => {
															setOpenDeleteGigPopup(true);
														}}
													>
														Delete
													</Button>
													<Button
														sx={{
															borderRadius: "24px",
															backgroundColor: "#FF702A",
															color: "#FFFFFF",
															width: "132px",
															height: " 40px",
															padding: "8px, 30.5px, 8px, 29.5px",
															justifyContent: "center",
															fontFamily: "Lexend",
															fontSize: "1.125rem",
															fontStyle: "normal",
															fontWeight: 500,
														}}
														disabled={user.userAccountStatus === "SUSPEND"}
														size="large"
														startIcon={<img src={EditIcon} alt="Export" />}
														variant="contained"
														onClick={() => setOpenEditGigPopup(true)}
													>
														Edit
													</Button>
												</>
											)}
									</Grid>
								</Grid>
							</Grid>
							{/* </Grid> */}
							<Grid item={true} xs={12} mt={3}>
								<Grid container={true} columns={{ xs: 12 }}>
									<Grid item xs={9} className="centerPanelwoRightEvent">
										<Grid item>
											<CardMedia
												component="img"
												image={
													gigDetail.bannerUrl !== null &&
													gigDetail.bannerUrl !== ""
														? `${process.env.REACT_APP_PICTURE_URL}${gigDetail.bannerUrl}`
														: process.env.REACT_APP_PICTURE_URL +
														  "/defaultimages/gig-banner.png"
												}
												sx={{
													borderRadius: "12px",
													objectFit: "fill",
													// width: "912px",
													// height: "230px",
												}}
											/>
										</Grid>
										{/* Event Details */}
										<Card
											sx={{
												// width: "912px",
												// height: "270px",
												borderRadius: "12px",
												marginTop: "30px",
												padding: "16px 22px 30px 62px",
											}}
										>
											<Grid
												container
												sx={{ textAlign: "right" }}
												direction={"row"}
												justifyContent={"flex-end"}
												alignItems={"center"}
												spacing={2}
											>
												{user?.userProfileId === gigDetail.userProfile?.id && (
													<Grid item={true}>
														<IconButton
															color="primary"
															size="small"
															onClick={handleShare}
															disabled={user.userAccountStatus === "SUSPEND"}
														>
															<img
																src={ShareIcon}
																alt="Share"
																style={{ height: "24px", width: "24px" }}
															/>
														</IconButton>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "14px !important",
																lineHeight: "20px",
																display: "contents",
																marginRight: "2rem",
															}}
														>
															Share
														</Typography>
													</Grid>
												)}
												<Grid item={true}>
													{liked ? (
														<IconButton
															color="primary"
															size="small"
															onClick={() => {
																handleLike(true);
															}}
															disabled={user.userAccountStatus === "SUSPEND"}
														>
															<ThumbUpIcon
																sx={{
																	color: "#3F77D7",
																	height: "24px",
																	width: "24px",
																}}
															/>
														</IconButton>
													) : (
														<IconButton
															color="primary"
															size="small"
															onClick={() => {
																handleLike(false);
															}}
															disabled={user.userAccountStatus === "SUSPEND"}
														>
															<img
																src={Like}
																alt="Like"
																style={{ height: "24px", width: "24px" }}
															/>
														</IconButton>
													)}
													<Typography
														sx={{
															fontFamily: "Lexend",
															fontStyle: "normal",
															fontWeight: "500",
															fontSize: "14px !important",
															lineHeight: "20px",
															display: "contents",
														}}
													>
														{likeCount} {likeCount<=1 ? 'Like':'Likes'}
													</Typography>
												</Grid>
											</Grid>
											<Grid container={true} columns={{ xs: 12 }}>
												<Grid item xs={6}>
													<Grid item>
														<IconButton color="primary" size="small">
															<img
																src={calendarIcon}
																alt="Date"
																style={{ height: "24px", width: "24px" }}
															/>
														</IconButton>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "18px !important",
																lineHeight: "22.5px",
																display: "contents",
															}}
														>
															{finalFormattedDate}
														</Typography>
													</Grid>
													<Grid item>
														<IconButton color="primary" size="small">
															<img
																src={Location}
																alt="Location"
																style={{ height: "24px", width: "24px" }}
															/>
														</IconButton>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "14px !important",
																lineHeight: "20px",
																display: "contents",
															}}
														>
															{gigDetail?.venue?.replace(/#/g, '')} {" "}
															{gigDetail.cityCountry}
														</Typography>
													</Grid>
													<Grid
														item
														sx={{ marginTop: "10px", paddingLeft: "5px" }}
													>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "24px !important",
																lineHeight: "30px",
															}}
														>
															Instrument required
														</Typography>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "16px !important",
																lineHeight: "20px",
																color: "#5B5B65",
																my: "10px",
															}}
														>
															<Stack
																direction="row"
																spacing={1}
																sx={{ flexWrap: "wrap" }}
																rowGap={1}
															>
																{instrument?.map((name: string) => (
																	<Chip
																		key={name}
																		avatar={getIcons(name)}
																		label={name}
																	/>
																))}
															</Stack>
														</Typography>
													</Grid>

													<Grid
														item
														sx={{ marginTop: "10px", paddingLeft: "5px" }}
													>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "24px !important",
																lineHeight: "30px",
															}}
														>
															Genre required
														</Typography>
														<Typography
															sx={{
																fontFamily: "Lexend",
																fontStyle: "normal",
																fontWeight: "500",
																fontSize: "16px !important",
																lineHeight: "20px",
																color: "#5B5B65",
																my: "10px",
															}}
														>
															<Stack
																direction="row"
																spacing={1}
																sx={{ flexWrap: "wrap" }}
																rowGap={1}
															>
																{genre?.map((name: string) => (
																	<Chip
																		key={name}
																		avatar={getGenre(name)}
																		label={name}
																	/>
																))}
															</Stack>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={6} pl={2}>
													<Typography
														sx={{
															fontFamily: "Lexend",
															fontStyle: "normal",
															fontWeight: "500",
															fontSize: "24px",
															lineHeight: "30px",
														}}
													>
														Payments
													</Typography>
													<Typography
														sx={{
															fontFamily: "Lexend",
															fontStyle: "normal",
															fontWeight: "500",
															fontSize: "1rem",
															lineHeight: "20px",
															color: "#5B5B65",
														}}
													>
														Starts at ${gigDetail.cost}/hr
													</Typography>
												</Grid>
											</Grid>
										</Card>

										<Card
											sx={{
												// width: "912px",
												// height: "257px",
												borderRadius: "12px",
												marginTop: "30px",
												marginBottom: "30px",
												padding: "6px",
											}}
										>
											<Grid
												container={true}
												columns={{ xs: 12 }}
												sx={{
													padding: "16px 22px 30px 62px",
													marginBottom: "40px",
												}}
												flexDirection={"column"}
											>
												{/* <Grid container={true} xs={6}> */}
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "24px !important",
														lineHeight: "30px",
													}}
												>
													Requirement
												</Typography>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "16px !important",
														lineHeight: "20px",
														color: "#5B5B65",
													}}
												>
													{gigDetail.requirements &&
													gigDetail.requirements !== "undefined"
														? gigDetail.requirements
														: "Not mentioned"}
												</Typography>

												{/* </Grid>
                                            <Grid container={true} xs={6} >                                                */}

												{/* </Grid> */}
											</Grid>
										</Card>

										{(gigDetail?.userProfile?.openForGigs ||
											gigDetail?.canApplyForGig) &&
											user?.userProfileId !== gigDetail?.userProfile?.id && (
												<Button
													sx={{
														borderRadius: "24px",
														color: "secondary",
														"&:hover": {
															backgroundColor: "customColor.orange",
															color: "secondary",
														},
														background:
															"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
														width: "239px",
														height: " 48px",
														padding: "12px 12px 12px 64px",
														justifyContent: "center",
														textAlign: "left",
														fontFamily: "Lexend",
														fontSize: "1.125rem",
														fontStyle: "normal",
														fontWeight: 500,
														lineHeight: "normal",
														letterSpacing: "0.9px",
														mb: "30px",
													}}
													size="large"
													endIcon={<img src={RightArrow} alt="Apply" />}
													variant="contained"
													onClick={() => setOpenSendMessage(true)}
													className={`submit-button`}
													disabled={
														user?.userAccountStatus === "SUSPEND" ||
														!gigDetail?.canApplyForGig
													}
												>
													<Typography>Apply for Gig</Typography>
												</Button>
											)}
									</Grid>
									<Grid item xs={2.6} className="rightPanelWidthEvent">
										<Card
											sx={{
												width: "240px",
												minHeight: "200px",
												padding: "10px 24px",
												textAlign: "center",
											}}
										>
											<Typography
												variant="body1"
												sx={{
													fontSize: "24px",
													fontWeight: "500",
													color: "#000",
												}}
											>
												Similar Gigs
											</Typography>
											<EventsSidePanel type={"GIG"} />
										</Card>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>

			{openEditGigPopup && (
				<NewGig
					open={openEditGigPopup}
					onSuccess={handleSuccess}
					onPostEventClose={handleOnClose}
					gigType={"modifyGig"}
					gigDetails={gigDetail}
					setAlertMsgMain={setAlertMsg}
					setShowAlertMain={setShowAlert}
					setAlertStatusMain={setAlertStatus}
				/>
			)}

			{openDeleteGigPopup && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteGigPopup}
					onClose={() => handleCloseDeletePopup()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeletePopup()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{
									marginLeft: "90%",
									position: "absolute",
									top: "8px",
								}}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete "{gigDetail.title}"</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleCloseDeletePopup()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => handleDelete()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openSendMessage && (
				<Dialog
					sx={{
						border: "1px solid #888888",
						boxShadow: "customColor.dropShadow",
						borderRadius: "8px",
					}}
					fullWidth
					maxWidth="sm"
					open={openSendMessage}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* <Box
							sx={{
								display: "flex",
								flexDirection: "row",
								// justifyContent: "center",
							}}
							>
						</Box> */}
						<Typography
							className="dialog-title-custom-size1"
							sx={{ color: "#000000" }}
							variant="h3"
						>
							Gig Application
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Grid
							container
							xs={12}
							// columnSpacing={2}
						>
							<Grid item xs={12}>
								<Typography
									className="dialog-title-custom-size1"
									sx={{ color: "#000000" }}
									variant="h3"
								>
									{gigDetail.title}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<IconButton color="primary" size="small">
									<img
										src={Location}
										alt="Location"
										style={{ height: "24px", width: "24px" }}
									/>
								</IconButton>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "14px !important",
										lineHeight: "20px",
										display: "contents",
									}}
								>
									{gigDetail.cityCountry}
								</Typography>
							</Grid>
							<Grid item={true} xs={12}>
								{/* <FormHelperText className="jamsession-label">
									{i18n.musicians.connection.label}
								</FormHelperText> */}
								<OutlinedInput
									id="title"
									rows={5}
									className="social-textField"
									sx={{
										borderColor: "#F0F0F0",
										pl: "20px",
										pr: "40px",
										flexDirection: "column",
										minHeight: "50px",
										fontSize: "16px !important",
										fontWeight: 400,
										"& .MuiOutlinedInput-input": {
											fontWeight: "400 !important",
										},
										"& .MuiOutlinedInput-notchedOutline": {
											borderColor: "#F0F0F0",
										},
										// height: "319px",
										// width: "97%",
										backgroundColor: "#F0F0F0",
										border: "none",
										borderRadius: "12px",
										resize: "none",
									}}
									value={gigMessage}
									onChange={(e: any) =>
										setGigMessage(e.target.value.trimStart())
									}
									placeholder="Comment on this"
									disabled={authorities?.includes("ROLE_ADMIN")}
									required
									multiline
									// error={
									//   dirtyComment &&
									//   (commentText?.length > 600)
									// }
									// onFocus={() => setDirtyComment(true)}
									endAdornment={
										<>
											{result || music ? (
												<Grid container>
													{result && (
														<>
															<Grid item xs={7}>
																<img
																	src={result}
																	alt="Caption this"
																	style={{ height: "200px", width: "100%" }}
																/>
															</Grid>
															<Grid item xs={5} sx={{ alignSelf: "center" }}>
																<IconButton
																	sx={{ alignSelf: "baseline" }}
																	onClick={() => handlePictureChange()}
																>
																	<img
																		src={CancelIcon}
																		style={{ height: "20px", width: "20px" }}
																		alt=""
																	/>
																</IconButton>
															</Grid>
														</>
													)}
													{music && (
														<>
															<Grid item xs={7}>
																{/* <CustomAudioPlayer audioUrl={music} displayDirectly={true} activeMenu="" /> */}
																<audio
																	crossOrigin="anonymous"
																	controls
																	controlsList="nodownload noremoteplayback noplaybackrate"
																>
																	<source src={music} type="audio/mpeg" />
																</audio>
															</Grid>
															<Grid item xs={5} sx={{ alignSelf: "center" }}>
																<IconButton
																	sx={{ alignSelf: "baseline" }}
																	onClick={() => handleMusicChange()}
																>
																	<img
																		src={CancelIcon}
																		style={{ height: "20px", width: "20px" }}
																		alt=""
																	/>
																</IconButton>
															</Grid>
														</>
													)}
												</Grid>
											) : (
												""
											)}
											{/* <InputAdornment position="end" sx={{ alignSelf: 'self-end', py: 1, marginTop: '-20px', mr: '-7%' }}>
											{adding ?
												<IconButton
													aria-label="comment"
													// onClick={() => sendComment()}
													edge="end"
													disabled
												>
													<img src={CreateCommentDisabled} alt='' />
												</IconButton>
												:
												<IconButton
													aria-label="comment"
													onClick={() => sendComment()}
													edge="end"
													disabled={isEnable()}
												>
													<img src={(commentText.trim().length > 0 && commentText.trim().length <= 600) || file !== '' || music !== '' ? CommentPostIcon : CreateCommentDisabled} alt='' />
												</IconButton>
											}
										</InputAdornment> */}
										</>
									}
								/>
								{/* <TextField
									inputProps={{
										style: {
											fontStyle: "normal",
											fontSize: "1.125rem",
											lineHeight: "25px",
											fontWeight: 900,
											color: "#000000",
										},
									}}
									sx={{ background: "#F8F8F8" }}
									fullWidth
									variant="outlined"
									value={gigMessage}
									multiline
									rows={2}
									// maxRows={5}
									onChange={(event) =>
										setGigMessage(event.target.value.trimStart())
									}
									error={gigMessage.length > 512}
								></TextField> */}
								<FormHelperText
									sx={{
										fontSize: "0.8125rem",
										color: "#FD4B24",
										textAlign: "right",
									}}
								>
									{gigMessage.length > 150
										? "Enter maximum 150 characters"
										: ""}
								</FormHelperText>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Grid
							container
							columnSpacing={1}
							sx={{
								display: "flex",
								flexDirection: "row",
								px: 1,
								// justifyContent: "flex-end",
							}}
						>
							<Grid item xs={2.5} sx={{ textAlignLast: "start" }}>
								<Button
									sx={{
										color: "black",
										fontSize: "14px",
										pt: "12px",
										height: "32px",
									}}
								>
									<label
										style={{
											cursor: "pointer",
											color: "black",
											fontSize: "14px",
										}}
									>
										<Grid container>
											<img
												src={Picture}
												alt=""
												style={{ height: "24px", width: "24px" }}
											/>
											&nbsp;
											<input
												id="photo-upload"
												// ref={inputFileRef}
												type="file"
												accept =".jpg, .jpeg, .png, .gif"
												style={{ display: "none" }}
												onChange={(e) => handleOpenEditor(e)}
											/>
											<Typography sx={{ color: "black", fontSize: "14px" }}>
												Photo
											</Typography>
										</Grid>
									</label>
								</Button>
							</Grid>
							<Grid item xs={3.5} sx={{ textAlignLast: "start" }}>
								<Button
									sx={{
										color: "black",
										fontSize: "14px",
										pt: "12px",
										height: "32px",
									}}
								>
									<label
										style={{
											cursor: "pointer",
											color: "black",
											fontSize: "14px",
										}}
									>
										<Grid container>
											<img
												src={Music}
												alt=""
												style={{ height: "24px", width: "24px" }}
											/>
											&nbsp;
											<input
												id="photo-upload"
												// ref={inputFileRef}
												type="file"
												accept="audio/*,.mp3,.wav,.mpeg"
												style={{ display: "none" }}
												onChange={(e) => handleOpenMusic(e)}
											/>
											<Typography sx={{ color: "black", fontSize: "14px" }}>
												Audio
											</Typography>
										</Grid>
									</label>
								</Button>
							</Grid>

							<Grid item={true} xs={4} sx={{ textAlignLast: "end" }}>
								<Button
									className="cancel-button"
									size="large"
									// endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									variant="outlined"
									onClick={() => handleGigClose()}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item={true} xs={2} sx={{ textAlignLast: "end" }}>
								{/* <Button
									className="submit-button"
									size="large"
									variant="contained"

									onClick={sendGigMessage}
								>
									Send
								</Button> */}
								{sending ? (
									<Button
										className="submit-button"
										variant="contained"
										sx={{
											// borderRadius: "17px",
											background: "#FF702A",
											// width: "40%",
										}}
										disabled={true}
									>
										{" "}
										Sending...
									</Button>
								) : (
									<Button
										className="submit-button"
										variant="contained"
										// sx={{ borderRadius: "17px", background: "#FF702A" }}
										onClick={() => sendMessageText()}
										// onClick={() => sendGigMessage()}
										disabled={!gigMessage.length}
									>
										{" "}
										Send &nbsp;
									</Button>
								)}
							</Grid>
						</Grid>
					</DialogActions>
					<ImageCropper
						open={open}
						onClose={handleClose}
						imagePreviewUrl={result}
						setImagePreviewUrl={setImagePreviewUrl}
						setResult={setFile}
						post={true}
						photoUpload={handleClose}
						cover={false}
						comment={true}
					/>
				</Dialog>
			)}
			{sharePost && (
				<CreatePostNew
					open={sharePost}
					eventPostDto={gigDetail}
					postType={"GIG"}
					editPost={false}
					location={"details"}
					handleClose={(call: boolean) => {
						handlePostClose(call);
					}}
				/>
			)}
		</>
	);
};

export default ViewGig;
