/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
	Typography,
	Box,
	Grid,
	Card,
	CardMedia,
	CardContent,
} from "@mui/material";
import Logo from "../images/LuteFish_Logo_White.svg";
// import BackgroundImage from "../images/cover_photo.jpg";
import BackgroundImage from "../images/cover_photo_new.png"; // remove this and enable above one while replacing to above image
import i18n from "../l10n/strings.json";
import TopGradientImg from "../images/top orange gradiant.png";
import BottomGradientImg from "../images/Bottom blue gradiant.png";

function LeftPanel() {
	const [cardContainerHeight, setCardContainerheight] = useState<
		number | undefined
	>(0);

	const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

	const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
	useEffect(() => {
		const logoElement = document.getElementById("wengor-logo-img");
		const logoRect = logoElement?.getBoundingClientRect();
		const titleElement = document.getElementById(
			"left-panel-title-custom-size"
		);
		const titleRect = titleElement?.getBoundingClientRect();
		if (logoRect?.height !== undefined && titleRect?.height !== undefined) {
			setCardContainerheight((logoRect?.height + titleRect?.height)/2);
		}

		window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
	}, []);

	return (
		<Grid
			container
			sx={{ backgroundColor: "#0273E6", height: "100vh" }}
			direction="row"
			xs={12}
		>
			<Grid item id="wengor-logo-img" sx={{width:"100%", height:"105px"}}>
				<Grid container>
					<Grid item xs={6} sx={{ padding: "24px 24px 0", }}>
						<img src={Logo} alt="LuteFish Logo" className="logo-custom-height"/>
					</Grid>
					<Grid item xs={6}>
						<Box display="flex" justifyContent="flex-end">
							<img
								src={TopGradientImg}
								alt="Top Gradient"
								style={{
									maxWidth: "75%",
									maxHeight: "75%",
									width: "auto",
									height: "auto",
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid item mt={0} className="tabImageLogin">
				<Card
					sx={{
						// height: "95%",
						boxShadow: "none",
					}}
				>
					<CardContent sx={{ backgroundColor: "#0273E6", padding: "0 30px 0 30px" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} id="left-panel-background-image">
								<Typography
									sx={{
										color: "#ffffff",
										fontWeight: 300,
										fontFamily: "Lexend",
										fontSize: "36px",
										lineHeight: "45px"
									}}
									id="left-panel-title-custom-size"
								>
									{i18n.leftPanel.title}
								</Typography>
							</Grid>
							{/* Remove this onClick while reverting back the image to original one */}
							<Grid item xs={12} style={{alignItems: "center", }} onClick={() =>  window.open('https://lutefish.com/pages/namm-giveaway')}>
								<CardMedia
									sx={{

										borderRadius: 4,
										// Remove below lines while reverting back the image to original one
										cursor: 'pointer',
										height: "323px",
										objectFit: "unset"
									}}

									//className="coverPhoto"
									component="img"
									image={BackgroundImage}
									alt="background image"
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Box position="absolute" left={0} bottom={0} width="100%">
					<Grid container>
						<Grid item xs={5} sm={5} md={2} lg={2} overflow="hidden">
							<Box display="flex" justifyContent="flex-start">
								<img
									src={BottomGradientImg}
									alt="bottom gradient"
									style={{
										maxWidth: "75%",
										maxHeight: "75%",
										width: "auto",
										height: "auto",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
}

export default LeftPanel;
