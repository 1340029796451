import { useState, ChangeEvent, useEffect } from "react";
import {
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	FormHelperText,
	DialogActions,
	IconButton,
	Box,
	Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// contexts
import { useAlertStatus } from "../context/AlertStatusContext";
import { useUser } from "../context/UserContext";
import CloseIcon from "../images/close.svg";
import RightAarrow from "../images/right arrow.svg";
import Calender from "../images/calendar.svg";
import i18n from "../l10n/strings.json";
import { useTheme } from "@mui/material/styles";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { json } from "stream/consumers";
import User from "../services/user";

const AddDeviceDialog = (props: any) => {
	const theme = useTheme();
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();
	const location = useLocation();
	const token = localStorage.getItem("sessionId");
	const [serialNumber, setSerialNumber] = useState("");
	const [dirty, setDirty] = useState(false);
	const [error, setError] = useState("");
	const [info, setInfo] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		setSerialNumber("");
		setError("");
		setInfo("");
	}, [props.open]);

	const {adminSetUserId} = useAdminContext();
	const {userId, userProfileId, email, user1: userDetails, userName,userStatus,userObject} = location?.state || {}

	const addDeviceHandler = () => {
		const authorities = user.authorities.map(
			(authority: { name: string }) => authority.name
		);
		const fetchhDeviceOf = isAdminUser() ? userObject.user : user;
		//fetch(`${process.env.REACT_APP_BASE_URL}/api/user-devices/${user.id}`, {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-devices`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				// id: 1,
				deviceId: serialNumber.toUpperCase(),
				status: "Active",
				user: fetchhDeviceOf,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.status === 502) {
					navigate("/defaultPage");
				} else if (result.code === 1012) {
					props.onClose();
					updateAlertStatus(true, false, result.message);
					if (props.updateAlertMsg) {
						props.updateAlertMsg(true, result.message);
					}
				} else if (result.code === 1010) {
					props.onClose();
					updateAlertStatus(true, false, i18n.settings.deviceSetup.error);
					if (props.updateAlertMsg) {
						props.updateAlertMsg(true, i18n.settings.deviceSetup.error);
					}
				} else if (result.code === 1013) {
					updateAlertStatus(true, false, result.message);
				}
				//  commented for now: overlapping with ping device message
				else if (result.code === 1011) {
					// updateAlertStatus(true, true, i18n.settings.deviceSetup.success);
					props.onSuccess();
					props.onClose();
				}
				User.fetchUserInfo((result: any) => {});
			});
	};

	const isAdminUser = () => {
		if (user?.authorities[0].name == 'ROLE_USER' || user?.authorities[0].name == 'ROLE_COLLAB_USER') {
			return false;
		} else {
			return true;
		}
	};
	const handleClose = (event: any, reason: string) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		props.onClose();
	};

	const handleSerialNumberChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const uppercaseValue = event.target.value.toUpperCase();
		const value = uppercaseValue.trim();

		// Invalid characters
		const invalidCharacters = [
			"#",
			"%",
			"+",
			"&",
			";",
			"=",
			"'",
			'"',
			"<",
			">",
			"!",
			"@",
			"$",
			"^",
			"*",
			"/",
		];

		const containsInvalidCharacters = invalidCharacters.some((char) =>
			value.includes(char)
		);

		if (containsInvalidCharacters) {
			setError("Special characters are not allowed.");
		} else if (value.length < 0) {
			setError("Please enter a serial number.");
		} else if (value.length > 12) {
			setInfo("Serial number cannot exceed 12 characters.");
		} else {
			if(value.length <=12){
				setSerialNumber(value);
				setError("");
				setInfo("");
			}
		}
	};

	useEffect(() => {
		if (props.open) {
			setTimeout(() => {
				const inputField = document.getElementById(
					"serial-number"
				) as HTMLInputElement | null;
				if (inputField) {
					inputField.focus();
				}
			}, 500);
		}
	}, [props.open]);

	return (
		<Dialog
			sx={{
				border: 1,
				borderColor: "customColor.gray4",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			PaperProps={{
				style: {
				  width: '400px',
				  maxWidth: '400px' // Custom maxWidth
				}
			  }}
			onClose={handleClose}
			open={props.open}
		>
			<DialogTitle
				sx={{
					display: "flex",
					flexDirection: "column",
					textAlign: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={props.onClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%",padding:'0px' }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography variant="h2" color="customColor.black">
						{i18n.settings.deviceSetup.dialogTitle}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid container columnSpacing={2}>
					<Grid item={true} xs={12}>
					
						<Typography color="customColor.label" sx={{ fontSize: '1rem', marginBottom: '36px', width: '340px' }}>
						Please enter the Product ID printed on the bottom of your Lutefish Stream.
						</Typography>
					
						
							<Typography variant="body4" color="customColor.label" >
							Device Code
							</Typography>

						<TextField
							sx={{
								input: {
									color: "customColor.black",
									fontSize: theme.typography.subtitle1.fontSize,
									fontWeight: theme.typography.subtitle1.fontWeight,
									backgroundColor: "customColor.gray1",
								},
								borderRadius: "6px",
								marginTop:'10px',
							}}
							fullWidth
							id="serial-number"
							value={serialNumber}
							onChange={handleSerialNumberChange}
							// onChange={(
							// 	event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
							// ) => setSerialNumber(event.target.value.trim())}

							onFocus={() => setDirty(true)}
							variant="outlined"
							inputProps={{
								style: {
									width: "100%",
								},
							}}
						/>
						{dirty && error ? (
							<FormHelperText sx={{ textAlign: "right" }}>
								<Typography variant="body2" color="error">
									{error}
								</Typography>
							</FormHelperText>
						) : info ? (
							<FormHelperText sx={{ textAlign: "right" }}>
								<Typography variant="body2" color="error">
									{info}
								</Typography>
							</FormHelperText>
						) : ""}


						{/* {dirty && serialNumber.length !== 0 && !error && (
    <FormHelperText
      sx={{
        textAlign: "right",
      }}
    >
      <Typography variant="body2" color="error.main">
        Enter a valid serial number
      </Typography>
    </FormHelperText>
  )} */}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ mr: 2 }}>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{
								color: "customColor.orange",
								borderRadius: "24px",
								borderColor: "customColor.orange",
								"&:hover": {
									borderColor: "customColor.orange",
								},
								width:'150px',
							}}
							size="large"
							endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							variant="outlined"
							onClick={props.onClose}
						>
							<Typography>{i18n.dialog.cancel}</Typography>
						</Button>
					</Grid>
					<Grid item={true}>
						<Button
							sx={{
								borderRadius: "24px",
								backgroundColor: "customColor.orange",
								color: "secondary",
								"&:hover": {
									backgroundColor: "customColor.orange",
									color: "secondary",
								},
								width:'150px',
							}}
							size="large"
							variant="contained"
							disabled={serialNumber === "" || error !== ""}
							onClick={() => addDeviceHandler()}
						>
							Add Device
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default AddDeviceDialog;
